<template>
  <section>
    <div>
      <custome-loader v-if="waitTimeoutContact"></custome-loader>
      <v-row
        class="mb-5 text-center my-5 mx-5"
        v-if="user.grade === 'administrateur' || user.grade === 'superadministrateur'"
      >
        <v-col class="d-flex" cols="12" md="4">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card rounded="xl" flat outlined class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on">
                <v-card-subtitle class="EoleYellow--text text-center font-weight-bold">
                  Demandes de rappels traitées </v-card-subtitle
                ><v-row>
                  <v-col align-self="center">
                    <v-card-text v-if="contactTreated.length > 0" class="text-center pa-5 mt-n5">
                      <v-badge :content="contactTreated.length" color="EoleGreen" bottom overlap>
                        <i class="fa-solid fa-phone-slash fa-4x EoleBlue--text"></i>
                      </v-badge>
                      <p class="mt-5">
                        a traité {{ contactTreated.length }} demandes de rappel depuis son inscription.
                      </p>
                    </v-card-text>
                    <v-card-text v-else class="text-center pa-5 mt-n5">
                      <i class="fa-solid fa-slash fa-4x EoleBlue--text"></i>
                      <p class="mt-5">Aucune demande de rappel traitées.</p>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </template>
            <span>Les appels traités sont affichés dans la liste ci-dessous. </span>
          </v-tooltip>
        </v-col>
        <v-col class="d-flex" cols="12" md="4">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card rounded="xl" flat outlined class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on">
                <v-card-subtitle class="EoleYellow--text text-center font-weight-bold">
                  Demandes de rappels en cours</v-card-subtitle
                ><v-row>
                  <v-col align-self="center">
                    <v-card-text v-if="contact.length > 0" class="text-center pa-5 mt-n5">
                      <v-badge :content="contact.length" color="EoleYellow" bottom overlap>
                        <i class="fa-solid fa-phone-volume fa-4x EoleBlue--text"></i>
                      </v-badge>
                      <p class="mt-5">a {{ contact.length }} demandes de rappels en cours.</p>
                    </v-card-text>
                    <v-card-text v-else class="text-center pa-5 mt-n5">
                      <i class="fa-solid fa-slash fa-4x EoleBlue--text"></i>
                      <p class="mt-5">Aucune demande de rappel en cours.</p>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </template>
            <span>Les appels traités sont affichés dans la liste ci-dessous. </span>
          </v-tooltip>
        </v-col>
        <v-col class="d-flex" cols="12" md="4">
          <v-card rounded="xl" flat outlined class="flex d-flex flex-column rounded-lg">
            <v-card-subtitle class="EoleYellow--text text-center font-weight-bold"
              >Tendances de l'agence</v-card-subtitle
            >
            <v-card-subtitle>Sur les 5 derniers jours</v-card-subtitle>
            <v-card-text class="text-center mx-auto pa-5 mt-n5">
              <apexchart
                class="mx-auto"
                v-if="dataGraphLoaded"
                type="line"
                height="130"
                :options="chartOptions"
                :series="chartSeries"
              ></apexchart>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-15 mb-5 text-center" v-else-if="user.grade === 'collaborateur' || user.grade === 'manager'">
        <v-col class="d-flex" cols="12" md="4">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card
                link
                @click="tab = 'tab-1'"
                rounded="xl"
                flat
                outlined
                class="flex d-flex flex-column rounded-lg"
                v-bind="attrs"
                v-on="on"
              >
                <v-card-subtitle class="EoleYellow--text text-center font-weight-bold"
                  >Mes demandes de rappels en attente</v-card-subtitle
                >
                <v-row>
                  <v-col align-self="center">
                    <v-card-text v-if="contact.length > 0" class="text-center pa-5 mt-n5">
                      <v-badge :content="contact.length" color="EoleError" bottom overlap>
                        <i class="fa-solid fa-phone-volume fa-4x EoleBlue--text"></i>
                      </v-badge>
                      <p class="mt-5">Vous avez {{ contact.length }} demandes de rappel en attente de traitement.</p>
                    </v-card-text>
                    <v-card-text v-else class="text-center pa-5 mt-n5">
                      <i class="fa-solid fa-briefcase fa-4x EoleBlue--text"></i>
                      <p class="mt-5">Aucune demande de rappel</p>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </template>
            <span>Les appels en attente sont affichés dans la liste ci-dessous. </span>
          </v-tooltip>
        </v-col>
        <v-col class="d-flex" cols="12" md="4">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card
                link
                @click="tab = 'tab-2'"
                rounded="xl"
                flat
                outlined
                class="flex d-flex flex-column rounded-lg"
                v-bind="attrs"
                v-on="on"
              >
                <v-card-subtitle class="EoleYellow--text text-center font-weight-bold"
                  >Mes demandes de rappels traitées</v-card-subtitle
                ><v-row>
                  <v-col align-self="center">
                    <v-card-text v-if="contactTreated.length > 0" class="text-center pa-5 mt-n5">
                      <v-badge :content="contactTreated.length" color="EoleGreen" bottom overlap>
                        <i class="fa-solid fa-phone-slash fa-4x EoleBlue--text"></i>
                      </v-badge>
                      <p class="mt-5">
                        Vous avez traité {{ contactTreated.length }} demandes de rappel depuis votre inscription.
                      </p>
                    </v-card-text>
                    <v-card-text v-else class="text-center pa-5 mt-n5">
                      <i class="fa-solid fa-briefcase fa-4x EoleBlue--text"></i>
                      <p class="mt-5">Aucune demande de rappel traitées.</p>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </template>
            <span>Les appels traités sont affichés dans la liste ci-dessous. </span>
          </v-tooltip>
        </v-col>

        <v-col class="d-flex" cols="12" md="4">
          <v-card rounded="xl" flat outlined class="flex d-flex flex-column rounded-lg">
            <v-card-subtitle class="EoleYellow--text text-center font-weight-bold">Tendances</v-card-subtitle>
            <v-card-subtitle>Sur les 5 derniers jours</v-card-subtitle>
            <v-card-text class="text-center mx-auto pa-5 mt-n5">
              <apexchart
                class="mx-auto"
                v-if="dataGraphLoaded"
                type="line"
                height="130"
                :options="chartOptions"
                :series="chartSeries"
              ></apexchart>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="user.grade === 'administrateur' || user.grade === 'superadministrateur'" class="my-5 mx-5">
        <v-col cols="12" md="3">
          <v-card>
            <v-card-text class="no-padding w-100">
              <v-navigation-drawer permanent width="100%">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6"> Outils </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
                <v-list dense>
                  <v-dialog v-model="dialog" max-width="1200" :persistent="loaderContact">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-icon>
                          <v-icon size="20">fa-phone-volume</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>Ajouter une demande de rappel</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                      <v-card-text style="background-color: #f1f4f9">
                        <v-row>
                          <v-col cols="12" md="3" class="no-padding ml-n2">
                            <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                              <v-card-title>
                                <v-img
                                  :src="require('@/assets/images/logos/anavel_logo.png')"
                                  max-height="150px"
                                  max-width="120px"
                                  alt="logo"
                                  contain
                                ></v-img>
                              </v-card-title>
                              <v-card-title class="text-center justify-center muller-capitalized"
                                >Mes Services Assurance</v-card-title
                              >
                              <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                              <v-card-text class="no-padding">
                                <v-img
                                  :src="require('@/assets/images/logos/layer.png')"
                                  alt="logo"
                                  width="300px"
                                  class=""
                                >
                                </v-img>
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col cols="12" md="9" class="no-padding d-flex">
                            <v-card
                              class="rounded-r-xl flex-fill flex d-flex flex-column"
                              flat
                              :loading="loaderContact"
                            >
                              <v-card-title>
                                <span class="text-h5">Effectuer une nouvelle demande de rappel</span>
                              </v-card-title>
                              <v-card-text>
                                <v-stepper v-model="e1" flat>
                                  <v-stepper-items>
                                    <v-stepper-content :step="1" class="text-center transparent-bg">
                                      <v-card-subtitle>Quelle est la typologie de votre client ? </v-card-subtitle>
                                      <v-row class="text-center align-center d-inline-flex row-study mb-5">
                                        <v-col
                                          cols="12"
                                          md="6"
                                          v-for="item in clientTypologyProposed"
                                          v-bind:key="item.name"
                                        >
                                          <v-card
                                            @mouseover="item.hovered = true"
                                            @mouseleave="item.hovered = false"
                                            v-on:click="
                                              clientTypologie = item.value
                                              e1 = item.e1
                                            "
                                            outlined
                                            width="200"
                                            height="200"
                                            elevation="3"
                                            class="align-center d-flex rounded-xl"
                                          >
                                            <v-card-text class="text-center pa-5">
                                              <i
                                                :class="[
                                                  'mt-4 fa-solid ',
                                                  item.icon,
                                                  'fa-5x',
                                                  item.hovered ? 'EoleYellow--text' : 'EoleBlue--text',
                                                ]"
                                              ></i>
                                              <p class="mt-2">{{ item.name }}</p>
                                            </v-card-text>
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                    </v-stepper-content>

                                    <v-stepper-content step="2">
                                      <v-card-text v-if="!!clientTypologie && clientTypologie === 1">
                                        <v-container>
                                          <v-form ref="formContact" v-model="validityFormContact" lazy-validation>
                                            <v-row>
                                              <v-col cols="12" sm="6" md="4">
                                                <v-select
                                                  dense
                                                  :rules="genderRules"
                                                  prepend-inner-icon="mdi-account"
                                                  outlined
                                                  :items="['M.', 'Mme', 'Mlle']"
                                                  label="Genre"
                                                  v-model="editedItem.gender"
                                                  required
                                                >
                                                </v-select>
                                              </v-col>
                                              <v-col cols="12" sm="6" md="4">
                                                <v-text-field
                                                  dense
                                                  :rules="lastnameRules"
                                                  prepend-inner-icon="mdi-account"
                                                  outlined
                                                  label="Nom"
                                                  v-model="editedItem.lastName"
                                                  required
                                                >
                                                </v-text-field>
                                              </v-col>
                                              <v-col cols="12" sm="6" md="4">
                                                <v-text-field
                                                  dense
                                                  :rules="firstnameRules"
                                                  prepend-inner-icon="mdi-account"
                                                  outlined
                                                  label="Prénom"
                                                  v-model="editedItem.firstName"
                                                  required
                                                >
                                                </v-text-field>
                                              </v-col>
                                              <v-col cols="6" class="mt-n5">
                                                <v-text-field
                                                  dense
                                                  @input="onPaste"
                                                  :rules="phoneRules"
                                                  counter="10"
                                                  prepend-inner-icon="mdi-phone"
                                                  outlined
                                                  label="Numéro de téléphone"
                                                  v-model="editedItem.phoneNumber"
                                                  required
                                                >
                                                </v-text-field>
                                              </v-col>
                                              <v-col cols="6"> </v-col>
                                              <v-col cols="6" class="mt-n5">
                                                <v-text-field
                                                  dense
                                                  prepend-inner-icon="mdi-email"
                                                  outlined
                                                  label="Email"
                                                  v-model="editedItem.email"
                                                  required
                                                >
                                                </v-text-field>
                                              </v-col>
                                              <v-col cols="6" class="mt-n5">
                                                <v-text-field
                                                  dense
                                                  counter="8"
                                                  prepend-inner-icon="mdi-identifier"
                                                  outlined
                                                  label="ID GRC"
                                                  v-model="editedItem.idGRC"
                                                  required
                                                >
                                                </v-text-field>
                                              </v-col>
                                              <v-col cols="12" sm="6" class="mt-n5">
                                                <v-select
                                                  dense
                                                  :rules="agencyRules"
                                                  prepend-inner-icon="mdi-office-building-marker"
                                                  outlined
                                                  :items="['Combourg', 'Rennes', 'St Grégoire']"
                                                  label="Agence"
                                                  v-model="editedItem.agence"
                                                  required
                                                >
                                                </v-select>
                                              </v-col>
                                              <v-col cols="12" sm="6" class="mt-n5">
                                                <v-select
                                                  dense
                                                  @change="func"
                                                  :rules="collaboratorRules"
                                                  prepend-inner-icon="mdi-account-tie"
                                                  outlined
                                                  :items="collaboratorsAvailable"
                                                  item-text="completName"
                                                  label="Attribuer à"
                                                  v-model="editedItem.id"
                                                  item-value="id"
                                                  required
                                                >
                                                  <template v-slot:item="{ item }">
                                                    <v-list-item-avatar size="24" v-if="item.avatarUrl != null">
                                                      <v-img :src="item.avatarUrl"></v-img>
                                                    </v-list-item-avatar>
                                                    <v-list-item-avatar size="24" v-else color="EoleBlue">
                                                      <span
                                                        class="white--text text-center ml-1"
                                                        style="font-size: 0.7rem"
                                                        >{{ item.firstname[0] + item.lastname[0] }}</span
                                                      >
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                      <v-list-item-title v-html="item.completName">{{
                                                        item.contact_number
                                                      }}</v-list-item-title>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                      <v-chip
                                                        class="ma-3"
                                                        v-if="item.solicitationStatus === 1"
                                                        color="EoleErrorBase"
                                                        text-color="white"
                                                        small
                                                      >
                                                        Très solicité</v-chip
                                                      >
                                                      <v-chip
                                                        class="ma-3"
                                                        v-else-if="item.solicitationStatus === 2"
                                                        color="EoleYellow"
                                                        small
                                                        >Solicité</v-chip
                                                      >
                                                    </v-list-item-action>
                                                  </template>
                                                </v-select>
                                              </v-col>
                                              <v-col cols="12" class="mt-n5">
                                                <!-- <v-textarea
                                                  dense
                                                  :rules="reasonRules"
                                                  prepend-inner-icon="mdi-message-reply-text"
                                                  outlined
                                                  label="Motif de l'appel"
                                                  v-model="editedItem.reason"
                                                  required
                                                >
                                                </v-textarea> -->
                                                <vue2-tinymce-editor v-model="editedItem.reason"></vue2-tinymce-editor>
                                              </v-col>
                                            </v-row>
                                          </v-form>
                                        </v-container>
                                      </v-card-text>
                                      <v-card-text v-if="!!clientTypologie && clientTypologie === 2">
                                        <v-container>
                                          <v-form ref="formContactPro" v-model="validityFormContactPro" lazy-validation>
                                            <v-row>
                                              <v-col cols="12" sm="6" md="12">
                                                <v-text-field
                                                  dense
                                                  prepend-inner-icon="mdi-account"
                                                  outlined
                                                  label="Raison sociale"
                                                  v-model="editedItem.lastName"
                                                  required
                                                >
                                                </v-text-field>
                                              </v-col>
                                              <v-col cols="6">
                                                <v-text-field
                                                  dense
                                                  @input="onPaste"
                                                  :rules="phoneRules"
                                                  counter="10"
                                                  prepend-inner-icon="mdi-phone"
                                                  outlined
                                                  label="Numéro de téléphone"
                                                  v-model="editedItem.phoneNumber"
                                                  required
                                                >
                                                </v-text-field>
                                              </v-col>
                                              <v-col cols="6"> </v-col>
                                              <v-col cols="6">
                                                <v-text-field
                                                  dense
                                                  prepend-inner-icon="mdi-email"
                                                  outlined
                                                  label="Email"
                                                  v-model="editedItem.email"
                                                  required
                                                >
                                                </v-text-field>
                                              </v-col>
                                              <v-col cols="6">
                                                <v-text-field
                                                  dense
                                                  counter="8"
                                                  prepend-inner-icon="mdi-identifier"
                                                  outlined
                                                  label="ID GRC"
                                                  v-model="editedItem.idGRC"
                                                  required
                                                >
                                                </v-text-field>
                                              </v-col>
                                              <v-col cols="12" sm="6">
                                                <v-select
                                                  dense
                                                  :rules="agencyRules"
                                                  prepend-inner-icon="mdi-office-building-marker"
                                                  outlined
                                                  :items="['Combourg', 'Rennes', 'St Grégoire']"
                                                  label="Agence"
                                                  v-model="editedItem.agence"
                                                  required
                                                >
                                                </v-select>
                                              </v-col>
                                              <v-col cols="12" sm="6">
                                                <v-select
                                                  dense
                                                  @change="func"
                                                  :rules="collaboratorRules"
                                                  prepend-inner-icon="mdi-account-tie"
                                                  outlined
                                                  :items="collaboratorsAvailable"
                                                  item-text="completName"
                                                  label="Attribuer à"
                                                  v-model="editedItem.id"
                                                  item-value="id"
                                                  required
                                                >
                                                  <template v-slot:item="{ item }">
                                                    <v-list-item-avatar size="24">
                                                      <v-img :src="item.avatarUrl"></v-img>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                      <v-list-item-title v-html="item.completName"></v-list-item-title>
                                                    </v-list-item-content>
                                                  </template>
                                                </v-select>
                                              </v-col>
                                              <v-col cols="12">
                                                <!-- <v-textarea
                                                  dense
                                                  :rules="reasonRules"
                                                  prepend-inner-icon="mdi-message-reply-text"
                                                  outlined
                                                  label="Motif de l'appel"
                                                  v-model="editedItem.reason"
                                                  required
                                                >
                                                </v-textarea> -->
                                                <vue2-tinymce-editor v-model="editedItem.reason"></vue2-tinymce-editor>
                                              </v-col>
                                            </v-row>
                                          </v-form>
                                        </v-container>
                                      </v-card-text>
                                      <v-card-actions>
                                        <v-btn v-if="e1 > 0" text @click="e1--" :disabled="loaderContact">
                                          retour
                                        </v-btn>

                                        <v-spacer></v-spacer>

                                        <v-btn
                                          text
                                          @click="editedIndex === -1 && validateFormContact(editedItem.id)"
                                          :loading="loaderContact"
                                        >
                                          Terminer
                                        </v-btn>
                                      </v-card-actions>
                                    </v-stepper-content>
                                  </v-stepper-items>
                                </v-stepper>
                              </v-card-text>
                              <v-spacer></v-spacer>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-dialog>

                  <v-dialog v-model="dialogExportContacts" max-width="1200">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-icon>
                          <v-icon size="20">fa-file-excel</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Exporter les demandes de rappels</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                      <v-card-text style="background-color: #f1f4f9">
                        <v-row>
                          <v-col cols="12" md="3" class="no-padding ml-n2">
                            <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                              <v-card-title>
                                <v-img
                                  :src="require('@/assets/images/logos/anavel_logo.png')"
                                  max-height="150px"
                                  max-width="120px"
                                  alt="logo"
                                  contain
                                ></v-img>
                              </v-card-title>
                              <v-card-title class="text-center justify-center muller-capitalized"
                                >Mes Services Assurance</v-card-title
                              >
                              <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                              <v-card-text class="no-padding">
                                <v-img
                                  :src="require('@/assets/images/logos/layer.png')"
                                  alt="logo"
                                  width="300px"
                                  class=""
                                >
                                </v-img>
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col cols="12" md="9" class="no-padding d-flex">
                            <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                              <v-card-title>
                                <span class="text-h5">Exporter les demandes de rappel</span>
                              </v-card-title>
                              <v-card-text>
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <v-text-field
                                      type="date"
                                      label="Date de début"
                                      v-model="exportStartDate"
                                      required
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-text-field
                                      type="date"
                                      label="Date de fin"
                                      v-model="exportEndDate"
                                      required
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                              <v-spacer></v-spacer>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text @click="exportContacts" :loading="loaderExportContacts"> Exporter </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-list>

                <v-divider></v-divider>
              </v-navigation-drawer>
            </v-card-text>
            <v-card-text class="mb-n5 mt-5">
              <v-autocomplete
                dense
                outlined
                no-data-text="Une erreur est survenue"
                filled
                clearable
                label="Recherche spécifique..."
                v-model="select"
                :search-input.sync="search"
                :items="users"
                :item-text="getItemText"
                item-value="id"
              >
              </v-autocomplete>
            </v-card-text>
            <v-card-text class="pa-5 no-padding">
              <v-treeview
                dense
                :load-children="fetchUsers"
                open-all
                activatable
                :active.sync="active"
                :items="items"
                :open.sync="open"
                color="EoleYellow"
                transition
                selected-color="EoleYellow"
              >
                <template v-slot:prepend="{ item }">
                  <v-icon v-if="item.children">mdi-phone</v-icon>
                  <v-spacer></v-spacer>
                  <v-badge
                    :color="item.available === 0 ? 'EoleYellow' : 'EoleGreen'"
                    value="5"
                    class="my-2 mr-2"
                    v-if="item.id && !item.children"
                    bordered
                    dot
                    bottom
                    overlap
                  >
                    <v-avatar size="40" :color="item.avatarUrl === null ? 'EoleBlue' : null" class="elevation-7">
                      <v-img :src="item.avatarUrl" v-if="item.avatarUrl != null"> </v-img>
                      <span v-else class="white--text">{{ item.firstname[0] + item.lastname[0] }}</span>
                      <!-- <v-icon v-else-if="item.id && !item.children" size="36">mdi-account</v-icon> -->
                    </v-avatar>
                  </v-badge>

                  {{ item.completName }}
                </template>
              </v-treeview>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-center no-padding align-center mt-4 mr-2 EoleBlue--text">
              <p class="text-caption">{{ contactTotalCount }} appels en attente</p>
              <br />
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" md="9">
          <v-scroll-y-transition mode="out-in">
            <v-row v-if="!selected" align="center" class="text-left">
              <v-col>
                <v-alert type="info" color="EoleBlue" prominent icon="mdi-account" text dense>
                  Veuillez selectionner un partenaire à afficher dans cette fenêtre.</v-alert
                >
              </v-col>
            </v-row>
            <v-card v-else :key="selected.id" class="mx-auto" flat>
              <v-card outlined class="text-center">
                <v-card-text class="profilBannerAccount" style="height: 100px">
                  <v-row>
                    <v-col cols="12" md="6" class="text-left"> </v-col>
                    <v-col cols="12" md="6" class="text-right"> </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text class="profilSeparator">
                  <v-divider></v-divider>
                </v-card-text>
                <v-card-text class="profilBadge">
                  <v-avatar v-if="avatarUrl != null" ref="avatar" size="96" class="elevation-7">
                    <v-img :src="selected.avatarUrl"> </v-img>
                  </v-avatar>
                  <v-avatar ref="avatar" size="96" color="EoleBlue" v-else>
                    <span class="white--text text-h3">{{ selected.firstname[0] + selected.lastname[0] }}</span>
                  </v-avatar>
                </v-card-text>
                <v-card-text class="profilTabs mb-15">
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-tabs
                        color="EoleYellow"
                        class="text-left mt-5"
                        v-model="tab"
                        style="box-shadow: none !important"
                        background-color="transparent"
                        :show-arrows="false"
                      >
                        <v-tab href="#tab-1">
                          Demandes de rappel
                          <!-- <v-icon>mdi-phone</v-icon> -->
                        </v-tab>

                        <v-tab href="#tab-2">
                          Demandes traitées
                          <!-- <v-icon>mdi-calendar-refresh</v-icon> -->
                        </v-tab>
                        <v-tab href="#tab-3">
                          Demandes envoyées
                          <!-- <v-icon>mdi-calendar-refresh</v-icon> -->
                        </v-tab>
                        <v-tab href="#tab-4">
                          Statistiques
                          <!-- <v-icon>mdi-calendar-refresh</v-icon> -->
                        </v-tab>
                      </v-tabs>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text class="mt-16">
                  <v-tabs-items v-model="tab" style="background-color: transparent !important">
                    <v-tab-item :value="'tab-1'" class="mb-5">
                      <v-row v-if="contact.length > 0">
                        <v-col class="d-flex" cols="12" md="4" v-for="(call, index) in contact" :key="index">
                          <v-card
                            outlined
                            elevation="3"
                            class="text-center flex d-flex flex-column rounded-xl call-card"
                          >
                            <v-row class="pa-5">
                              <v-col class="text-left" cols="12" md="9">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" color="EoleBlue">
                                      <v-icon>fa-circle-info</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                    Demande créée le {{ call.datetime }} <br />
                                    par {{ call.attributor.lastname }} {{ call.attributor.firstname }} <br />
                                  </span>
                                </v-tooltip>
                              </v-col>
                              <v-col class="text-right" cols="12" md="3">
                                <v-menu right transition="slide-x-transition" offset-x>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                      <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                  </template>

                                  <v-list dense>
                                    <v-subheader>Mes actions disponibles</v-subheader>

                                    <v-list-item link color="EoleBlue">
                                      <v-dialog transition="slide-x-transition" max-width="1200">
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-list-item-title v-bind="attrs" v-on="on">
                                            Assurer le suivi
                                          </v-list-item-title>
                                          <v-list-item-action>
                                            <i class="fa-solid fa-circle-info ml-2"></i>
                                          </v-list-item-action>
                                        </template>
                                        <template v-slot:default="dialogTrace">
                                          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                                            <v-card-text style="background-color: #f1f4f9">
                                              <v-row>
                                                <v-col cols="12" md="3" class="no-padding ml-n2">
                                                  <v-card
                                                    class="rounded-l-xl"
                                                    color="#f1f4f9"
                                                    style="background-color: #f1f4f9"
                                                    flat
                                                  >
                                                    <v-card-title>
                                                      <v-img
                                                        :src="require('@/assets/images/logos/anavel_logo.png')"
                                                        max-height="150px"
                                                        max-width="120px"
                                                        alt="logo"
                                                        contain
                                                      ></v-img>
                                                    </v-card-title>
                                                    <v-card-title class="text-center justify-center muller-capitalized"
                                                      >Mes Services Assurance</v-card-title
                                                    >
                                                    <v-card-subtitle class="text-center justify-center"
                                                      >Un logiciel ANAVEL</v-card-subtitle
                                                    >
                                                    <v-card-text class="no-padding">
                                                      <v-img
                                                        :src="require('@/assets/images/logos/layer.png')"
                                                        alt="logo"
                                                        width="300px"
                                                        class=""
                                                      >
                                                      </v-img>
                                                    </v-card-text>
                                                  </v-card>
                                                </v-col>
                                                <v-col cols="12" md="9" class="no-padding d-flex">
                                                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                                                    <v-card-title class="text-h5">
                                                      <v-row>
                                                        <v-col cols="12" md="10" class="text-left"
                                                          >Suivi de la demande</v-col
                                                        >
                                                        <v-col cols="12" md="2"> </v-col>
                                                      </v-row>
                                                    </v-card-title>

                                                    <v-card-text class="timeline-container"
                                                      ><v-timeline dense clipped>
                                                        <v-timeline-item
                                                          fill-dot
                                                          class="white--text mb-12"
                                                          color="EoleBlue"
                                                          large
                                                        >
                                                          <template v-slot:icon>
                                                            <v-avatar
                                                              v-if="avatarUrlCollaborator != null"
                                                              ref="avatar"
                                                              size="48"
                                                              color=""
                                                              class="elevation-10"
                                                            >
                                                              <v-img :src="avatarUrlCollaborator"> </v-img>
                                                            </v-avatar>
                                                            <v-avatar ref="avatar" size="48" color="EoleBlue" v-else>
                                                              <span class="white--text text-h5">{{
                                                                $store.state.user.firstname[0] +
                                                                $store.state.user.lastname[0]
                                                              }}</span>
                                                            </v-avatar>
                                                          </template>
                                                          <v-textarea
                                                            no-resize
                                                            v-model="traceComment"
                                                            hide-details
                                                            flat
                                                            outlined
                                                            label="Laisser un commentaire"
                                                          >
                                                            <template v-slot:append>
                                                              <v-btn
                                                                :disabled="traceComment === ''"
                                                                class="mx-0"
                                                                depressed
                                                                @click="postComment(call, traceComment)"
                                                              >
                                                                Ajouter
                                                              </v-btn>
                                                            </template>
                                                          </v-textarea>
                                                        </v-timeline-item>
                                                        <v-timeline-item
                                                          v-for="(log, index) in reverseTrace(call.trace)"
                                                          :key="index"
                                                          color="EoleBlue"
                                                        >
                                                          <template v-slot:icon>
                                                            <i
                                                              v-if="log.id_action == 3"
                                                              class="fa-solid fa-share-from-square white--text"
                                                            ></i>
                                                            <i
                                                              v-else-if="log.id_action == 2"
                                                              class="fa-solid fa-comment white--text"
                                                            ></i>
                                                          </template>
                                                          <v-row justify="space-between">
                                                            <v-col cols="7">
                                                              <span class="font-weight-bold">Action: </span
                                                              >{{ log.action || "Non spécifiée" }}<br />
                                                              <span v-if="log.comments">
                                                                <span class="font-weight-bold">Commentaires: </span
                                                                >{{ log.comments }}
                                                              </span>
                                                              <span v-else> Pas de commentaire </span><br />
                                                              <span>
                                                                <span class="font-weight-bold"
                                                                  >Action réalisée par: </span
                                                                >{{ log.firstname }}
                                                              </span>
                                                            </v-col>
                                                          </v-row>

                                                          <template>
                                                            {{ log.datetime }}
                                                          </template>
                                                        </v-timeline-item>

                                                        <v-timeline-item color="EoleGreen">
                                                          <template v-slot:icon>
                                                            <i class="fa-solid fa-folder-open white--text"></i>
                                                          </template>
                                                          <v-row justify="space-between">
                                                            <v-col cols="7">
                                                              <span class="font-weight-bold">Action: </span>Ouverture de
                                                              la demande #{{ call.idContact }}<br />
                                                            </v-col>
                                                          </v-row>

                                                          <template>
                                                            {{ call.datetime }}
                                                          </template>
                                                        </v-timeline-item>
                                                      </v-timeline>
                                                    </v-card-text>
                                                    <v-spacer></v-spacer>
                                                  </v-card>
                                                </v-col>
                                              </v-row>
                                            </v-card-text>
                                          </v-card>
                                        </template>
                                      </v-dialog>
                                    </v-list-item>
                                    <v-list-item link color="EoleBlue" :disabled="transferInProgress">
                                      <v-dialog
                                        transition="slide-x-transition"
                                        max-width="1200"
                                        v-model="dialogTransferContact"
                                        :persistent="transferInProgress"
                                        close-on-content-click
                                      >
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-list-item-title v-bind="attrs" v-on="on"
                                            >Assigner à nouveau</v-list-item-title
                                          >
                                          <v-list-item-action>
                                            <i class="fa-solid fa-share-nodes ml-2"></i>
                                          </v-list-item-action>
                                        </template>
                                        <template v-slot:default="dialogContact">
                                          <v-card
                                            class="rounded-l-xl"
                                            style="box-shadow: 0 0 60px -20px #000000d9"
                                            min-height="800px"
                                            max-height="800px"
                                          >
                                            <v-card-text style="background-color: #f1f4f9">
                                              <v-row>
                                                <v-col cols="12" md="3" class="no-padding ml-n2">
                                                  <v-card
                                                    min-height="800px"
                                                    max-height="800px"
                                                    class="rounded-l-xl"
                                                    color="#f1f4f9"
                                                    style="background-color: #f1f4f9"
                                                    flat
                                                  >
                                                    <v-card-title>
                                                      <v-img
                                                        :src="require('@/assets/images/logos/anavel_logo.png')"
                                                        max-height="150px"
                                                        max-width="120px"
                                                        alt="logo"
                                                        contain
                                                      ></v-img>
                                                    </v-card-title>
                                                    <v-card-title class="text-center justify-center muller-capitalized"
                                                      >Mes Services Assurance</v-card-title
                                                    >
                                                    <v-card-subtitle class="text-center justify-center"
                                                      >Un logiciel ANAVEL</v-card-subtitle
                                                    >
                                                    <v-card-text class="no-padding">
                                                      <v-img
                                                        :src="require('@/assets/images/logos/layer.png')"
                                                        alt="logo"
                                                        width="300px"
                                                        class=""
                                                      >
                                                      </v-img>
                                                    </v-card-text>
                                                  </v-card>
                                                </v-col>
                                                <v-col cols="12" md="9" class="no-padding d-flex">
                                                  <v-card
                                                    class="rounded-r-xl flex-fill flex d-flex flex-column"
                                                    flat
                                                    :loading="transferInProgress"
                                                  >
                                                    <v-card-title class="text-h5">
                                                      <v-row>
                                                        <v-col cols="12" md="10" class="text-left"
                                                          >Changement d'assignation</v-col
                                                        >
                                                        <v-col cols="12" md="2">
                                                          <v-chip>Appel n°{{ call.idContact }}</v-chip>
                                                        </v-col>
                                                      </v-row>
                                                    </v-card-title>
                                                    <v-card-subtitle
                                                      >Sélectionnez un collaborateur dans la liste pour changer
                                                      l'assignation de cette demande</v-card-subtitle
                                                    >
                                                    <v-card-text class="mb-n5">
                                                      <v-row>
                                                        <v-col cols="12" md="12">
                                                          <v-text-field
                                                            dense
                                                            prepend-inner-icon="mdi-account-search"
                                                            outlined
                                                            label="Rechercher un collaborateur"
                                                            v-model="searchCollaborator"
                                                            @input="searchCollaborators"
                                                          ></v-text-field>
                                                        </v-col>
                                                      </v-row>
                                                    </v-card-text>
                                                    <v-card-text class="no-padding">
                                                      <v-list dense :disabled="transferInProgress">
                                                        <v-list-item
                                                          v-for="(collaborator, index) in collaboratorFiltering(
                                                            collaboratorsAvailable,
                                                          )"
                                                          :key="index"
                                                          :disabled="transferInProgress || collaborator.available === 0"
                                                          color="EoleBlue"
                                                          link
                                                          @click="
                                                            transferContact(
                                                              call.idContact,
                                                              collaborator.id,
                                                              collaborator.firstname,
                                                              collaborator.email,
                                                            )
                                                          "
                                                        >
                                                          <v-badge
                                                            :color="
                                                              collaborator.available === 0 ? 'EoleYellow' : 'EoleGreen'
                                                            "
                                                            bordered
                                                            dot
                                                            bottom
                                                            overlap
                                                            offset-x="25"
                                                            offset-y="15"
                                                          >
                                                            <v-list-item-avatar
                                                              v-if="collaborator.avatarUrl != null"
                                                              class="elevation-10"
                                                            >
                                                              <v-img :src="collaborator.avatarUrl"></v-img>
                                                            </v-list-item-avatar>
                                                            <v-list-item-avatar
                                                              v-else
                                                              color="EoleBlue"
                                                              class="text-center"
                                                            >
                                                              <v-avatar color="red">
                                                                <span class="white--text mr-2">{{
                                                                  collaborator.firstname[0] + collaborator.lastname[0]
                                                                }}</span>
                                                              </v-avatar>
                                                            </v-list-item-avatar>
                                                          </v-badge>
                                                          <v-list-item-content>
                                                            <v-list-item-title>{{
                                                              collaborator.completName
                                                            }}</v-list-item-title>

                                                            <!-- <v-list-item-subtitle>
                                                        <v-sheet v-bind="attrs" v-on="on">
                                                          <apexchart
                                                            v-if="collaborator.last_5_days_contact"
                                                            type="line"
                                                            height="100"
                                                            :options="getChartOptions(collaborator)"
                                                            :series="getChartSeries(collaborator)"
                                                          ></apexchart>
                                                        </v-sheet>
                                                      </v-list-item-subtitle> -->
                                                          </v-list-item-content>
                                                          <v-list-item-action>
                                                            <!-- <v-chip
                                                              v-if="collaborator.available === 0"
                                                              color="EoleYellow"
                                                              text-color="white"
                                                              small
                                                            >
                                                              Indisponible
                                                            </v-chip> -->
                                                            <v-chip
                                                              v-if="collaborator.solicitationStatus === 1"
                                                              color="EoleErrorBase"
                                                              text-color="white"
                                                              small
                                                            >
                                                              Très solicité
                                                            </v-chip>
                                                            <v-chip
                                                              v-else-if="collaborator.solicitationStatus === 2"
                                                              color="EoleYellow"
                                                              small
                                                            >
                                                              Solicité
                                                            </v-chip>
                                                          </v-list-item-action>
                                                        </v-list-item>
                                                      </v-list>
                                                    </v-card-text>
                                                    <v-spacer></v-spacer>
                                                  </v-card>
                                                </v-col>
                                              </v-row>
                                            </v-card-text>
                                          </v-card>
                                        </template>
                                      </v-dialog>
                                    </v-list-item>

                                    <v-list-item link :disabled="transferInProgress" @click="prepareOutlookMail(call)">
                                      <v-list-item-title> Envoyer par email </v-list-item-title>

                                      <v-list-item-action>
                                        <svg
                                          class="ml-2"
                                          version="1.1"
                                          id="Livello_1"
                                          xmlns:x="http://ns.adobe.com/Extensibility/1.0/"
                                          xmlns:i="http://ns.adobe.com/AdobeIllustrator/10.0/"
                                          xmlns:graph="http://ns.adobe.com/Graphs/1.0/"
                                          xmlns="http://www.w3.org/2000/svg"
                                          xmlns:xlink="http://www.w3.org/1999/xlink"
                                          x="0px"
                                          y="0px"
                                          viewBox="0 0 1831.085 1703.335"
                                          enable-background="new 0 0 1831.085 1703.335"
                                          xml:space="preserve"
                                          width="20"
                                          height="20"
                                        >
                                          <path
                                            fill="#0A2767"
                                            d="M1831.083,894.25c0.1-14.318-7.298-27.644-19.503-35.131h-0.213l-0.767-0.426l-634.492-375.585  c-2.74-1.851-5.583-3.543-8.517-5.067c-24.498-12.639-53.599-12.639-78.098,0c-2.934,1.525-5.777,3.216-8.517,5.067L446.486,858.693  l-0.766,0.426c-19.392,12.059-25.337,37.556-13.278,56.948c3.553,5.714,8.447,10.474,14.257,13.868l634.492,375.585  c2.749,1.835,5.592,3.527,8.517,5.068c24.498,12.639,53.599,12.639,78.098,0c2.925-1.541,5.767-3.232,8.517-5.068l634.492-375.585  C1823.49,922.545,1831.228,908.923,1831.083,894.25z"
                                          />
                                          <path
                                            fill="#0364B8"
                                            d="M520.453,643.477h416.38v381.674h-416.38V643.477z M1745.917,255.5V80.908  c1-43.652-33.552-79.862-77.203-80.908H588.204C544.552,1.046,510,37.256,511,80.908V255.5l638.75,170.333L1745.917,255.5z"
                                          />
                                          <path fill="#0078D4" d="M511,255.5h425.833v383.25H511V255.5z" />
                                          <path
                                            fill="#28A8EA"
                                            d="M1362.667,255.5H936.833v383.25L1362.667,1022h383.25V638.75L1362.667,255.5z"
                                          />
                                          <path fill="#0078D4" d="M936.833,638.75h425.833V1022H936.833V638.75z" />
                                          <path fill="#0364B8" d="M936.833,1022h425.833v383.25H936.833V1022z" />
                                          <path fill="#14447D" d="M520.453,1025.151h416.38v346.969h-416.38V1025.151z" />
                                          <path fill="#0078D4" d="M1362.667,1022h383.25v383.25h-383.25V1022z" />
                                          <linearGradient
                                            id="SVGID_1_"
                                            gradientUnits="userSpaceOnUse"
                                            x1="1128.4584"
                                            y1="811.0833"
                                            x2="1128.4584"
                                            y2="1.9982"
                                            gradientTransform="matrix(1 0 0 -1 0 1705.3334)"
                                          >
                                            <stop offset="0" style="stop-color: #35b8f1" />
                                            <stop offset="1" style="stop-color: #28a8ea" />
                                          </linearGradient>
                                          <path
                                            fill="url(#SVGID_1_)"
                                            d="M1811.58,927.593l-0.809,0.426l-634.492,356.848c-2.768,1.703-5.578,3.321-8.517,4.769  c-10.777,5.132-22.481,8.029-34.407,8.517l-34.663-20.27c-2.929-1.47-5.773-3.105-8.517-4.897L447.167,906.003h-0.298  l-21.036-11.753v722.384c0.328,48.196,39.653,87.006,87.849,86.7h1230.914c0.724,0,1.363-0.341,2.129-0.341  c10.18-0.651,20.216-2.745,29.808-6.217c4.145-1.756,8.146-3.835,11.966-6.217c2.853-1.618,7.75-5.152,7.75-5.152  c21.814-16.142,34.726-41.635,34.833-68.772V894.25C1831.068,908.067,1823.616,920.807,1811.58,927.593z"
                                          />
                                          <path
                                            opacity="0.5"
                                            fill="#0A2767"
                                            enable-background="new    "
                                            d="M1797.017,891.397v44.287l-663.448,456.791L446.699,906.301  c0-0.235-0.191-0.426-0.426-0.426l0,0l-63.023-37.899v-31.938l25.976-0.426l54.932,31.512l1.277,0.426l4.684,2.981  c0,0,645.563,368.346,647.267,369.197l24.698,14.478c2.129-0.852,4.258-1.703,6.813-2.555  c1.278-0.852,640.879-360.681,640.879-360.681L1797.017,891.397z"
                                          />
                                          <path
                                            fill="#1490DF"
                                            d="M1811.58,927.593l-0.809,0.468l-634.492,356.848c-2.768,1.703-5.578,3.321-8.517,4.769  c-24.641,12.038-53.457,12.038-78.098,0c-2.918-1.445-5.76-3.037-8.517-4.769L446.657,928.061l-0.766-0.468  c-12.25-6.642-19.93-19.409-20.057-33.343v722.384c0.305,48.188,39.616,87.004,87.803,86.7c0.001,0,0.002,0,0.004,0h1229.636  c48.188,0.307,87.5-38.509,87.807-86.696c0-0.001,0-0.002,0-0.004V894.25C1831.068,908.067,1823.616,920.807,1811.58,927.593z"
                                          />
                                          <path
                                            opacity="0.1"
                                            enable-background="new    "
                                            d="M1185.52,1279.629l-9.496,5.323c-2.752,1.752-5.595,3.359-8.517,4.812  c-10.462,5.135-21.838,8.146-33.47,8.857l241.405,285.479l421.107,101.476c11.539-8.716,20.717-20.178,26.7-33.343L1185.52,1279.629  z"
                                          />
                                          <path
                                            opacity="0.05"
                                            enable-background="new    "
                                            d="M1228.529,1255.442l-52.505,29.51c-2.752,1.752-5.595,3.359-8.517,4.812  c-10.462,5.135-21.838,8.146-33.47,8.857l113.101,311.838l549.538,74.989c21.649-16.254,34.394-41.743,34.407-68.815v-9.326  L1228.529,1255.442z"
                                          />
                                          <path
                                            fill="#28A8EA"
                                            d="M514.833,1703.333h1228.316c18.901,0.096,37.335-5.874,52.59-17.033l-697.089-408.331  c-2.929-1.47-5.773-3.105-8.517-4.897L447.125,906.088h-0.298l-20.993-11.838v719.914  C425.786,1663.364,465.632,1703.286,514.833,1703.333C514.832,1703.333,514.832,1703.333,514.833,1703.333z"
                                          />
                                          <path
                                            opacity="0.1"
                                            enable-background="new    "
                                            d="M1022,418.722v908.303c-0.076,31.846-19.44,60.471-48.971,72.392  c-9.148,3.931-19,5.96-28.957,5.962H425.833V383.25H511v-42.583h433.073C987.092,340.83,1021.907,375.702,1022,418.722z"
                                          />
                                          <path
                                            opacity="0.2"
                                            enable-background="new    "
                                            d="M979.417,461.305v908.302c0.107,10.287-2.074,20.469-6.388,29.808  c-11.826,29.149-40.083,48.273-71.54,48.417H425.833V383.25h475.656c12.356-0.124,24.533,2.958,35.344,8.943  C962.937,405.344,979.407,432.076,979.417,461.305z"
                                          />
                                          <path
                                            opacity="0.2"
                                            enable-background="new    "
                                            d="M979.417,461.305v823.136c-0.208,43-34.928,77.853-77.927,78.225H425.833V383.25  h475.656c12.356-0.124,24.533,2.958,35.344,8.943C962.937,405.344,979.407,432.076,979.417,461.305z"
                                          />
                                          <path
                                            opacity="0.2"
                                            enable-background="new    "
                                            d="M936.833,461.305v823.136c-0.046,43.067-34.861,78.015-77.927,78.225H425.833  V383.25h433.072c43.062,0.023,77.951,34.951,77.927,78.013C936.833,461.277,936.833,461.291,936.833,461.305z"
                                          />
                                          <linearGradient
                                            id="SVGID_2_"
                                            gradientUnits="userSpaceOnUse"
                                            x1="162.7469"
                                            y1="1383.0741"
                                            x2="774.0864"
                                            y2="324.2592"
                                            gradientTransform="matrix(1 0 0 -1 0 1705.3334)"
                                          >
                                            <stop offset="0" style="stop-color: #1784d9" />
                                            <stop offset="0.5" style="stop-color: #107ad5" />
                                            <stop offset="1" style="stop-color: #0a63c9" />
                                          </linearGradient>
                                          <path
                                            fill="url(#SVGID_2_)"
                                            d="M78.055,383.25h780.723c43.109,0,78.055,34.947,78.055,78.055v780.723  c0,43.109-34.946,78.055-78.055,78.055H78.055c-43.109,0-78.055-34.947-78.055-78.055V461.305  C0,418.197,34.947,383.25,78.055,383.25z"
                                          />
                                          <path
                                            fill="#FFFFFF"
                                            d="M243.96,710.631c19.238-40.988,50.29-75.289,89.17-98.495c43.057-24.651,92.081-36.94,141.675-35.515  c45.965-0.997,91.321,10.655,131.114,33.683c37.414,22.312,67.547,55.004,86.742,94.109c20.904,43.09,31.322,90.512,30.405,138.396  c1.013,50.043-9.706,99.628-31.299,144.783c-19.652,40.503-50.741,74.36-89.425,97.388c-41.327,23.734-88.367,35.692-136.011,34.578  c-46.947,1.133-93.303-10.651-134.01-34.067c-37.738-22.341-68.249-55.07-87.892-94.28c-21.028-42.467-31.57-89.355-30.745-136.735  C212.808,804.859,223.158,755.686,243.96,710.631z M339.006,941.858c10.257,25.912,27.651,48.385,50.163,64.812  c22.93,16.026,50.387,24.294,78.353,23.591c29.783,1.178,59.14-7.372,83.634-24.358c22.227-16.375,39.164-38.909,48.715-64.812  c10.677-28.928,15.946-59.572,15.543-90.404c0.33-31.127-4.623-62.084-14.649-91.554c-8.855-26.607-25.246-50.069-47.182-67.537  c-23.88-17.79-53.158-26.813-82.91-25.55c-28.572-0.74-56.644,7.593-80.184,23.804c-22.893,16.496-40.617,39.168-51.1,65.365  c-23.255,60.049-23.376,126.595-0.341,186.728L339.006,941.858z"
                                          />
                                          <path fill="#50D9FF" d="M1362.667,255.5h383.25v383.25h-383.25V255.5z" />
                                        </svg>
                                      </v-list-item-action>
                                    </v-list-item>

                                    <v-divider class="my-2"></v-divider>
                                    <v-dialog transition="dialog-bottom-transition" max-width="400">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-list-item
                                          v-bind="attrs"
                                          v-on="on"
                                          color="EoleError"
                                          link
                                          @click="archiveContact(call.idContact)"
                                        >
                                          <v-list-item-title> Demande traitée</v-list-item-title>
                                          <v-list-item-action>
                                            <i class="fa-solid fa-circle-check ml-2 EoleGreen--text"></i>
                                          </v-list-item-action>
                                        </v-list-item>
                                      </template>
                                      <template v-slot:default="dialogArchiveContact">
                                        <v-card>
                                          <v-card-text class="text-center">
                                            <v-img
                                              src="https://anavel.bzh/assets/success.gif"
                                              max-width="126px"
                                              class="mx-auto"
                                            ></v-img>
                                            Votre demande à été traitée, il ne vous reste plus que
                                            <strong> {{ contact.length - 1 }} appels</strong> !
                                          </v-card-text>
                                        </v-card>
                                      </template>
                                    </v-dialog>
                                  </v-list>
                                </v-menu>
                              </v-col>
                            </v-row>
                            <v-card-text class="flex-grow-1">
                              <v-row class="flex-column">
                                <v-col class="text-right">
                                  <v-chip color="EoleYellow" small>Agence de {{ call.agency }}</v-chip>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    dense
                                    :id="`identity-${index}`"
                                    outlined
                                    label="Identité"
                                    append-icon="mdi-content-copy"
                                    readonly
                                    prepend-inner-icon="mdi-account"
                                    :value="call.lastname + ' ' + call.firstname"
                                    @click:append="copyClipboard(`identity-${index}`)"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="8">
                                  <v-text-field
                                    dense
                                    :id="`email-${index}`"
                                    outlined
                                    label="Email"
                                    append-icon="mdi-content-copy"
                                    readonly
                                    prepend-inner-icon="mdi-email-outline"
                                    :value="call.email"
                                    @click:append="copyClipboard(`email-${index}`)"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="2">
                                  <v-btn
                                    :disabled="call.email === null"
                                    class="white--text"
                                    :href="`mailto:${call.email}`"
                                    color="EoleBlue"
                                    >Contacter</v-btn
                                  >
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="8">
                                  <v-text-field
                                    dense
                                    :id="`phone-${index}`"
                                    outlined
                                    label="Numéro de téléphone"
                                    append-icon="mdi-content-copy"
                                    readonly
                                    prepend-inner-icon="mdi-phone"
                                    :value="call.phoneNumber"
                                    @click:append="copyClipboard(`phone-${index}`)"
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" md="2">
                                  <v-btn class="white--text" :href="`tel:${call.phoneNumber}`" color="EoleBlue"
                                    >Appeler</v-btn
                                  >
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    dense
                                    :id="`idGRC-${index}`"
                                    outlined
                                    label="Identifiant GRC"
                                    append-icon="mdi-content-copy"
                                    readonly
                                    prepend-inner-icon="mdi-identifier"
                                    :value="call.idGRC"
                                    @click:append="copyClipboard('idGRC')"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <!-- <v-textarea
                                    dense
                                    :id="`reason-${index}`"
                                    no-resize
                                    type="code"
                                    outlined
                                    label="Motif de l'appel"
                                    readonly
                                    :value="call.reason"
                                    rows="6"
                                    append-icon="mdi-content-copy"
                                    @click:append="copyClipboard(`reason-${index}`)"
                                  >
                                  </v-textarea> -->
                                  <v-card outlined class="text-left" min-height="100px">
                                    <v-card-text v-html="call.reason"></v-card-text>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-spacer></v-spacer>
                            <!-- <v-card-actions>
                        <v-row>
                          <v-col v-if="call.attributorName === null">
                            <v-skeleton-loader class="mx-auto" type="text"></v-skeleton-loader>
                          </v-col>

                          <v-col v-else class="text-center" cols="12" md="12"
                            >Demande créée le {{ call.datetime }}</v-col
                          >
                        </v-row>
                      </v-card-actions> -->
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row v-else class="text-center justify-center">
                        <v-alert color="transparent">
                          <h2>Bonjour {{ collaborator.firstname }},</h2>

                          <v-img
                            class="mx-auto"
                            max-width="350"
                            :src="require('@/assets/images/pictogram/contact/getting_coffee.png')"
                          ></v-img>
                          Vous n'avez aucune demande de rappel. <br />
                          N'hésitez pas à aller vous chercher un café et à repasser dans quelques instants. 😉
                        </v-alert>
                      </v-row>
                    </v-tab-item>

                    <v-tab-item :value="'tab-2'">
                      <v-card-text class="no-padding">
                        <v-row v-if="contactTreated.length > 0">
                          <v-col cols="12">
                            <v-data-table
                              item-key="idContact"
                              class="elevation-0 no-padding"
                              :loading="loading"
                              loading-text="Chargement des produits en cours... Merci de patienter"
                              :page.sync="page"
                              :items-per-page="10"
                              :headers="headersContactTreated"
                              :items="getContactsByFilter()"
                              hide-default-footer
                              @page-count="pageCount = $event"
                              sort-by="calories"
                              :footer-props="{
                                'items-per-page-text': 'produits par page(s)',
                              }"
                            >
                              <template v-slot:top>
                                <v-row>
                                  <v-col cols="12" md="4">
                                    <v-text-field
                                      prepend-icon="mdi-magnify"
                                      v-model="searchContactTreated"
                                      label="Rechercher parmi vos demandes traitées"
                                      class="mx-4"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </template>
                              <template v-slot:item.agency="{ item }">
                                <v-chip small color="EoleYellow"> Agence de {{ item.agency }} </v-chip>
                              </template>
                              <template v-slot:item.attributor="{ item }">
                                {{ item.attributor.firstname }} {{ item.attributor.lastname }}
                              </template>
                              <template v-slot:item.firstname="{ item }">
                                {{ item.lastname }} {{ item.firstname }}
                              </template>
                              <template v-slot:item.actions="{ item }">
                                <v-dialog max-width="1200">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" rounded icon fab>
                                      <i class="fa-solid fa-eye"></i>
                                    </v-btn>
                                  </template>
                                  <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                                    <v-card-text style="background-color: #f1f4f9">
                                      <v-row>
                                        <v-col cols="12" md="3" class="no-padding ml-n2">
                                          <v-card
                                            class="rounded-l-xl"
                                            color="#f1f4f9"
                                            style="background-color: #f1f4f9"
                                            flat
                                          >
                                            <v-card-title>
                                              <v-img
                                                :src="require('@/assets/images/logos/anavel_logo.png')"
                                                max-height="150px"
                                                max-width="120px"
                                                alt="logo"
                                                contain
                                              ></v-img>
                                            </v-card-title>
                                            <v-card-title class="text-center justify-center muller-capitalized"
                                              >Mes Services Assurance</v-card-title
                                            >
                                            <v-card-subtitle class="text-center justify-center"
                                              >Un logiciel ANAVEL</v-card-subtitle
                                            >
                                            <v-card-text class="no-padding">
                                              <v-img
                                                :src="require('@/assets/images/logos/layer.png')"
                                                alt="logo"
                                                width="300px"
                                                class=""
                                              >
                                              </v-img>
                                            </v-card-text>
                                          </v-card>
                                        </v-col>
                                        <v-col cols="12" md="9" class="no-padding d-flex">
                                          <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                                            <v-card-title>
                                              <span class="text-h5"
                                                >Votre demande portant le N°{{ item.idContact }}</span
                                              >
                                            </v-card-title>
                                            <v-card-text class="flex-grow-1">
                                              <v-row class="flex-column">
                                                <v-col class="text-left">Demande créée le {{ item.datetime }} </v-col>
                                                <v-col class="text-right">
                                                  <v-chip color="EoleYellow" small>Agence de {{ item.agency }}</v-chip>
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col>
                                                  <v-text-field
                                                    dense
                                                    id="identity"
                                                    outlined
                                                    label="Identité"
                                                    append-icon="mdi-content-copy"
                                                    readonly
                                                    prepend-inner-icon="mdi-account"
                                                    :value="item.lastname + ' ' + item.firstname"
                                                    @click:append="copyClipboard('identity')"
                                                  ></v-text-field>
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col>
                                                  <v-text-field
                                                    dense
                                                    id="email"
                                                    outlined
                                                    label="Email"
                                                    append-icon="mdi-content-copy"
                                                    readonly
                                                    prepend-inner-icon="mdi-email"
                                                    :value="item.email"
                                                    @click:append="copyClipboard('email')"
                                                  ></v-text-field>
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col cols="12" md="7">
                                                  <v-text-field
                                                    dense
                                                    id="phone"
                                                    outlined
                                                    label="Numéro de téléphone"
                                                    append-icon="mdi-content-copy"
                                                    readonly
                                                    prepend-inner-icon="mdi-phone"
                                                    :value="item.phoneNumber"
                                                    @click:append="copyClipboard('phone')"
                                                  ></v-text-field>
                                                </v-col>
                                                <v-col class="mt-2" cols="12" md="2">
                                                  <v-btn
                                                    class="white--text"
                                                    :href="`tel:${item.phoneNumber}`"
                                                    color="EoleBlue"
                                                    >Appeler</v-btn
                                                  >
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col>
                                                  <v-text-field
                                                    dense
                                                    id="idGRC"
                                                    outlined
                                                    label="Identifiant GRC"
                                                    append-icon="mdi-content-copy"
                                                    readonly
                                                    prepend-inner-icon="mdi-identifier"
                                                    :value="item.idGRC"
                                                    @click:append="copyClipboard('idGRC')"
                                                  ></v-text-field>
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col>
                                                  <v-textarea
                                                    dense
                                                    id="reason"
                                                    no-resize
                                                    type="code"
                                                    outlined
                                                    label="Motif de l'appel"
                                                    readonly
                                                    :value="item.reason"
                                                    rows="1"
                                                    auto-grow
                                                    append-icon="mdi-content-copy"
                                                    @click:append="copyClipboard('reason')"
                                                  ></v-textarea>
                                                </v-col>
                                              </v-row>
                                            </v-card-text>
                                            <v-spacer></v-spacer>
                                            <!-- <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-on="on"
                                v-bind="attrs"
                                :disabled="calculateDay - numDays < 0"
                                color="EoleGreen"
                                class="white--text"
                                @click="postHoliday"
                                >Confirmer ma demande</v-btn
                              >
                            </template>
                            <span>Cliquez-ici pour valider votre demande de congé</span>
                          </v-tooltip>
                        </v-card-actions> -->
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                </v-dialog>
                              </template>

                              <template v-slot:no-data>
                                <v-alert color="error" text class="ma-2">
                                  <div class="d-flex align-start">
                                    <v-icon color="error"> mdi-alert-circle-outline </v-icon>

                                    <div class="ms-3">
                                      <p class="text-base font-weight-medium mb-1">
                                        Impossible d'accéder aux données. Merci de réessayer ultérieurement.
                                      </p>
                                    </div>
                                  </div>
                                </v-alert>
                                <v-btn color="primary" class="mb-2" @click="fetchInsurersByProduct()"> Réessayer</v-btn>
                              </template>
                            </v-data-table>
                            <div class="text-center pt-2">
                              <v-pagination
                                v-model="page"
                                :length="pageCount"
                                :total-visible="5"
                                circle
                                navigation-color="EoleYellow"
                              ></v-pagination>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row v-else class="text-center justify-center">
                          <v-alert color="transparent">
                            <h2>Bonjour {{ collaborator.firstname }},</h2>

                            <v-img
                              class="mx-auto"
                              max-width="350"
                              :src="require('@/assets/images/pictogram/contact/getting_coffee.png')"
                            ></v-img>
                            Vous n'avez aucune demande de rappel. <br />
                            N'hésitez pas à aller vous chercher un café et à repasser dans quelques instants. 😉
                          </v-alert>
                        </v-row>
                      </v-card-text>
                    </v-tab-item>

                    <v-tab-item :value="'tab-3'">
                      <v-card-text class="no-padding">
                        <v-row v-if="contactTreated.length > 0">
                          <v-col cols="12">
                            <v-data-table
                              item-key="idContact"
                              class="elevation-0 no-padding"
                              :loading="loading"
                              loading-text="Chargement des produits en cours... Merci de patienter"
                              :page.sync="page"
                              :items-per-page="10"
                              :headers="headersContactSended"
                              :items="getContactsSendedByFilter()"
                              hide-default-footer
                              @page-count="pageCount = $event"
                              sort-by="calories"
                              :footer-props="{
                                'items-per-page-text': 'produits par page(s)',
                              }"
                            >
                              <template v-slot:top>
                                <v-row>
                                  <v-col cols="12" md="4">
                                    <v-text-field
                                      prepend-icon="mdi-magnify"
                                      v-model="searchContactSended"
                                      label="Rechercher parmi vos demandes traitées"
                                      class="mx-4"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </template>
                              <template v-slot:item.agency="{ item }">
                                <v-chip small color="EoleYellow"> Agence de {{ item.agency }} </v-chip>
                              </template>
                              <template v-slot:item.status="{ item }">
                                <v-chip v-if="item.source === 'history'" color="EoleGreen" small outlined
                                  >Demande traitée</v-chip
                                >
                                <v-chip v-else color="EoleError" small outlined>Demande non traitée</v-chip>
                              </template>
                              <template v-slot:item.receiver="{ item }">
                                {{ item.receiver.firstname }} {{ item.receiver.lastname }}
                              </template>
                              <template v-slot:item.firstname="{ item }">
                                {{ item.lastname }} {{ item.firstname }}
                              </template>
                              <template v-slot:item.actions="{ item }">
                                <v-dialog max-width="1200">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" rounded icon fab>
                                      <i class="fa-solid fa-eye"></i>
                                    </v-btn>
                                  </template>
                                  <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                                    <v-card-text style="background-color: #f1f4f9">
                                      <v-row>
                                        <v-col cols="12" md="3" class="no-padding ml-n2">
                                          <v-card
                                            class="rounded-l-xl"
                                            color="#f1f4f9"
                                            style="background-color: #f1f4f9"
                                            flat
                                          >
                                            <v-card-title>
                                              <v-img
                                                :src="require('@/assets/images/logos/anavel_logo.png')"
                                                max-height="150px"
                                                max-width="120px"
                                                alt="logo"
                                                contain
                                              ></v-img>
                                            </v-card-title>
                                            <v-card-title class="text-center justify-center muller-capitalized"
                                              >Mes Services Assurance</v-card-title
                                            >
                                            <v-card-subtitle class="text-center justify-center"
                                              >Un logiciel ANAVEL</v-card-subtitle
                                            >
                                            <v-card-text class="no-padding">
                                              <v-img
                                                :src="require('@/assets/images/logos/layer.png')"
                                                alt="logo"
                                                width="300px"
                                                class=""
                                              >
                                              </v-img>
                                            </v-card-text>
                                          </v-card>
                                        </v-col>
                                        <v-col cols="12" md="9" class="no-padding d-flex">
                                          <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                                            <v-card-title>
                                              <span class="text-h5"
                                                >Votre demande portant le N°{{ item.idContact }}</span
                                              >
                                            </v-card-title>
                                            <v-card-text class="flex-grow-1">
                                              <v-row>
                                                <v-col class="text-left" cols="12" md="6"
                                                  >Demande créée le {{ item.datetime }}
                                                </v-col>
                                                <v-col class="text-right" cols="12" md="6">
                                                  <v-chip v-if="item.source === 'history'" color="EoleGreen" small
                                                    >Demande traitée par {{ item.receiver.lastname }}
                                                    {{ item.receiver.firstname }}</v-chip
                                                  >
                                                  <v-chip v-else color="EoleError" small>Demande non traitée</v-chip>
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col class="text-right">
                                                  <v-chip color="EoleYellow" small>Agence de {{ item.agency }}</v-chip>
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col>
                                                  <v-text-field
                                                    dense
                                                    id="identity"
                                                    outlined
                                                    label="Identité"
                                                    append-icon="mdi-content-copy"
                                                    readonly
                                                    prepend-inner-icon="mdi-account"
                                                    :value="item.lastname + ' ' + item.firstname"
                                                    @click:append="copyClipboard('identity')"
                                                  ></v-text-field>
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col>
                                                  <v-text-field
                                                    dense
                                                    id="email"
                                                    outlined
                                                    label="Email"
                                                    append-icon="mdi-content-copy"
                                                    readonly
                                                    prepend-inner-icon="mdi-email"
                                                    :value="item.email"
                                                    @click:append="copyClipboard('email')"
                                                  ></v-text-field>
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col cols="12" md="7">
                                                  <v-text-field
                                                    dense
                                                    id="phone"
                                                    outlined
                                                    label="Numéro de téléphone"
                                                    append-icon="mdi-content-copy"
                                                    readonly
                                                    prepend-inner-icon="mdi-phone"
                                                    :value="item.phoneNumber"
                                                    @click:append="copyClipboard('phone')"
                                                  ></v-text-field>
                                                </v-col>
                                                <v-col class="mt-2" cols="12" md="2">
                                                  <v-btn
                                                    class="white--text"
                                                    :href="`tel:${item.phoneNumber}`"
                                                    color="EoleBlue"
                                                    >Appeler</v-btn
                                                  >
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col>
                                                  <v-text-field
                                                    dense
                                                    id="idGRC"
                                                    outlined
                                                    label="Identifiant GRC"
                                                    append-icon="mdi-content-copy"
                                                    readonly
                                                    prepend-inner-icon="mdi-identifier"
                                                    :value="item.idGRC"
                                                    @click:append="copyClipboard('idGRC')"
                                                  ></v-text-field>
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col>
                                                  <v-textarea
                                                    dense
                                                    id="reason"
                                                    no-resize
                                                    type="code"
                                                    outlined
                                                    label="Motif de l'appel"
                                                    readonly
                                                    :value="item.reason"
                                                    rows="1"
                                                    auto-grow
                                                    append-icon="mdi-content-copy"
                                                    @click:append="copyClipboard('reason')"
                                                  ></v-textarea>
                                                </v-col>
                                              </v-row>
                                            </v-card-text>
                                            <v-spacer></v-spacer>
                                            <!-- <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-on="on"
                                v-bind="attrs"
                                :disabled="calculateDay - numDays < 0"
                                color="EoleGreen"
                                class="white--text"
                                @click="postHoliday"
                                >Confirmer ma demande</v-btn
                              >
                            </template>
                            <span>Cliquez-ici pour valider votre demande de congé</span>
                          </v-tooltip>
                        </v-card-actions> -->
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                </v-dialog>
                              </template>

                              <template v-slot:no-data>
                                <v-alert color="error" text class="ma-2">
                                  <div class="d-flex align-start">
                                    <v-icon color="error"> mdi-alert-circle-outline </v-icon>

                                    <div class="ms-3">
                                      <p class="text-base font-weight-medium mb-1">
                                        Impossible d'accéder aux données. Merci de réessayer ultérieurement.
                                      </p>
                                    </div>
                                  </div>
                                </v-alert>
                                <v-btn color="primary" class="mb-2" @click="fetchInsurersByProduct()"> Réessayer</v-btn>
                              </template>
                            </v-data-table>
                            <div class="text-center pt-2">
                              <v-pagination
                                v-model="page"
                                :length="pageCount"
                                :total-visible="5"
                                circle
                                navigation-color="EoleYellow"
                              ></v-pagination>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row v-else class="text-center justify-center">
                          <v-alert color="transparent">
                            <h2>Bonjour {{ collaborator.firstname }},</h2>

                            <v-img
                              class="mx-auto"
                              max-width="350"
                              :src="require('@/assets/images/pictogram/contact/getting_coffee.png')"
                            ></v-img>
                            Vous n'avez aucune demande de rappel. <br />
                            N'hésitez pas à aller vous chercher un café et à repasser dans quelques instants. 😉
                          </v-alert>
                        </v-row>
                      </v-card-text>
                    </v-tab-item>

                    <v-tab-item :value="'tab-4'">
                      <v-card-text class="text-left">
                        <v-row>
                          <v-col class="d-flex" cols="12" md="6" sm="12">
                            <v-card class="flex d-flex flex-column rounded-lg" outlined>
                              <v-row>
                                <v-col cols="12" md="3" class="text-left">
                                  <v-card-title class="text-left">
                                    <v-avatar color="EoleBlueLighten" class="mr-2">
                                      <i
                                        class="fa-solid fa-chart-simple fa-1x EoleBlue--text"
                                        style="font-size: 1.5rem"
                                      ></i> </v-avatar
                                  ></v-card-title>
                                </v-col>
                                <v-col cols="12" md="9" class="text-left ml-n15">
                                  <v-card-title class="text-left">Répartition par services des demandes</v-card-title>
                                  <v-card-subtitle class="text-left">Pour l'année en cours</v-card-subtitle>
                                </v-col>
                              </v-row>

                              <v-row align="center">
                                <v-card-text class="text-center align-center">
                                  <apexchart
                                    :options="monthlyRequestsServicesChartOptions"
                                    :series="monthlyRequestsServicesSeries"
                                    type="bar"
                                  ></apexchart>
                                </v-card-text>
                              </v-row>
                            </v-card>
                          </v-col>
                          <!-- <v-col class="d-flex" cols="12" md="6" sm="12">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-card class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on" outlined>
                            <v-row>
                              <v-col cols="12" md="2" class="text-left">
                                <v-card-title class="text-left">
                                  <v-avatar color="EoleBlueLighten" class="mr-2">
                                    <i
                                      class="fa-solid fa-chart-simple fa-1x EoleBlue--text"
                                      style="font-size: 1.5rem"
                                    ></i> </v-avatar
                                ></v-card-title>
                              </v-col>
                              <v-col cols="12" md="10" class="text-left ml-n15">
                                <v-card-title class="text-left"
                                  >Répartition total des demandes traitées pour l'agence</v-card-title
                                >
                                <v-card-subtitle class="text-left">Pour l'année en cours</v-card-subtitle>
                              </v-col>
                            </v-row>
                           
                            <v-row align="center">
                              <v-card-text class="text-center align-center">
                                <apexchart
                                  :options="monthlyRequestsChartOptions"
                                  :series="monthlyRequestsSeries"
                                  type="bar"
                                ></apexchart>
                              </v-card-text>
                            </v-row>
                          </v-card>
                        </template>
                        <span>Le nombre de réclamations de l'agence. </span>
                      </v-tooltip>
                    </v-col> -->
                          <v-col class="d-flex" cols="12" md="6" sm="12">
                            <v-card class="flex d-flex flex-column rounded-lg" outlined>
                              <v-row>
                                <v-col cols="12" md="3" class="text-left">
                                  <v-card-title class="text-left">
                                    <v-avatar color="EoleBlueLighten" class="mr-2">
                                      <i
                                        class="fa-solid fa-chart-simple fa-1x EoleBlue--text"
                                        style="font-size: 1.5rem"
                                      ></i> </v-avatar
                                  ></v-card-title>
                                </v-col>
                                <v-col cols="12" md="9" class="text-left ml-n15">
                                  <v-card-title class="text-left">Répartition sur son service</v-card-title>
                                  <v-card-subtitle class="text-left">Sur les demandes en cours</v-card-subtitle>
                                </v-col>
                              </v-row>
                              <!-- <v-card-subtitle
                              ><v-avatar color="EoleBlueLighten" class="mr-2">
                                <i class="fa-solid fa-chart-simple fa-1x EoleBlue--text" style="font-size: 1.5rem"></i>
                              </v-avatar>
                              Mes dernières demandes
                              </v-card-subtitle
                            >
                            <v-card-subtitle>
                              Sur les 7 derniers jours
                              </v-card-subtitle> -->

                              <v-row align="center">
                                <v-card-text class="text-center align-center">
                                  <apexchart
                                    type="pie"
                                    :options="pieChartOptions"
                                    :series="pieSeries"
                                    height="250px"
                                  ></apexchart>
                                </v-card-text>
                              </v-row>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="d-flex" cols="12" md="6" sm="6">
                            <v-card class="flex d-flex flex-column rounded-lg" outlined>
                              <v-row>
                                <v-col cols="12" md="3" class="text-left">
                                  <v-card-title class="text-left">
                                    <v-avatar color="EoleBlueLighten" class="mr-2">
                                      <i
                                        class="fa-solid fa-chart-simple fa-1x EoleBlue--text"
                                        style="font-size: 1.5rem"
                                      ></i> </v-avatar
                                  ></v-card-title>
                                </v-col>
                                <v-col cols="12" md="9" class="text-left ml-n15">
                                  <v-card-title class="text-left">Les dernières demandes reçus</v-card-title>
                                  <v-card-subtitle class="text-left">Sur les 7 derniers jours</v-card-subtitle>
                                </v-col>
                              </v-row>
                              <!-- <v-card-subtitle
                              ><v-avatar color="EoleBlueLighten" class="mr-2">
                                <i class="fa-solid fa-chart-simple fa-1x EoleBlue--text" style="font-size: 1.5rem"></i>
                              </v-avatar>
                              Mes dernières demandes
                              </v-card-subtitle
                            >
                            <v-card-subtitle>
                              Sur les 7 derniers jours
                              </v-card-subtitle> -->

                              <v-row align="center">
                                <v-card-text class="text-center align-center">
                                  <apexchart type="bar" :options="chartOptions" :series="series"></apexchart>
                                </v-card-text>
                              </v-row>
                            </v-card>
                          </v-col>
                          <v-col class="d-flex" cols="12" md="6" sm="6">
                            <v-card class="flex d-flex flex-column rounded-lg" outlined>
                              <v-row>
                                <v-col cols="12" md="3" class="text-left">
                                  <v-card-title class="text-left">
                                    <v-avatar color="EoleBlueLighten" class="mr-2">
                                      <i
                                        class="fa-solid fa-chart-simple fa-1x EoleBlue--text"
                                        style="font-size: 1.5rem"
                                      ></i> </v-avatar
                                  ></v-card-title>
                                </v-col>
                                <v-col cols="12" md="9" class="text-left ml-n15">
                                  <v-card-title class="text-left">Les demandes reçus traitées</v-card-title>
                                  <v-card-subtitle class="text-left">Sur les dernières années</v-card-subtitle>
                                </v-col>
                              </v-row>
                              <!-- <v-card-subtitle
                              ><v-avatar color="EoleBlueLighten" class="mr-2">
                                <i class="fa-solid fa-chart-simple fa-1x EoleBlue--text" style="font-size: 1.5rem"></i>
                              </v-avatar>
                              Mes dernières demandes
                              </v-card-subtitle
                            >
                            <v-card-subtitle>
                              Sur les 7 derniers jours
                              </v-card-subtitle> -->

                              <v-row align="center">
                                <v-card-text class="text-center align-center">
                                  <apexchart
                                    :options="annualChartOptions"
                                    :series="annualSeries"
                                    type="bar"
                                  ></apexchart>
                                </v-card-text>
                              </v-row>
                            </v-card>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col class="d-flex" cols="12" md="6" sm="6">
                            <v-card class="flex d-flex flex-column rounded-lg" outlined>
                              <v-row>
                                <v-col cols="12" md="3" class="text-left">
                                  <v-card-title class="text-left">
                                    <v-avatar color="EoleBlueLighten" class="mr-2">
                                      <i
                                        class="fa-solid fa-share-from-square fa-1x EoleBlue--text"
                                        style="font-size: 1.5rem"
                                      ></i> </v-avatar
                                  ></v-card-title>
                                </v-col>
                                <v-col cols="12" md="9" class="text-left ml-n15">
                                  <v-card-title class="text-left">Les demandes envoyés</v-card-title>
                                  <v-card-subtitle class="text-left">Sur les 7 derniers jours</v-card-subtitle>
                                </v-col>
                              </v-row>
                              <!-- <v-card-subtitle
                              ><v-avatar color="EoleBlueLighten" class="mr-2">
                                <i class="fa-solid fa-chart-simple fa-1x EoleBlue--text" style="font-size: 1.5rem"></i>
                              </v-avatar>
                              Mes dernières demandes
                              </v-card-subtitle
                            >
                            <v-card-subtitle>
                              Sur les 7 derniers jours
                              </v-card-subtitle> -->

                              <v-row align="center">
                                <v-card-text class="text-center align-center">
                                  <apexchart type="bar" :options="sendChartOptions" :series="sendSeries"></apexchart>
                                </v-card-text>
                              </v-row>
                            </v-card>
                          </v-col>
                          <v-col class="d-flex" cols="12" md="6" sm="6">
                            <v-card class="flex d-flex flex-column rounded-lg" outlined>
                              <v-row>
                                <v-col cols="12" md="3" class="text-left">
                                  <v-card-title class="text-left">
                                    <v-avatar color="EoleBlueLighten" class="mr-2">
                                      <i
                                        class="fa-solid fa-share-from-square fa-1x EoleBlue--text"
                                        style="font-size: 1.5rem"
                                      ></i> </v-avatar
                                  ></v-card-title>
                                </v-col>
                                <v-col cols="12" md="9" class="text-left ml-n15">
                                  <v-card-title class="text-left">Les demandes envoyées</v-card-title>
                                  <v-card-subtitle class="text-left">Sur les dernières années</v-card-subtitle>
                                </v-col>
                              </v-row>
                              <!-- <v-card-subtitle
                              ><v-avatar color="EoleBlueLighten" class="mr-2">
                                <i class="fa-solid fa-chart-simple fa-1x EoleBlue--text" style="font-size: 1.5rem"></i>
                              </v-avatar>
                              Mes dernières demandes
                              </v-card-subtitle
                            >
                            <v-card-subtitle>
                              Sur les 7 derniers jours
                              </v-card-subtitle> -->

                              <v-row align="center">
                                <v-card-text class="text-center align-center">
                                  <apexchart
                                    :options="annualSendChartOptions"
                                    :series="annualSendSeries"
                                    type="bar"
                                  ></apexchart>
                                </v-card-text>
                              </v-row>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </v-card>
            </v-card>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
      <div v-else-if="user.grade === 'collaborateur' || user.grade === 'manager'">
        <v-card outlined class="text-center">
          <v-card-text class="profilBannerAccount" style="height: 100px">
            <v-row>
              <v-col cols="12" md="6" class="text-left"> </v-col>
              <v-col cols="12" md="6" class="text-right"> </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="profilSeparator">
            <v-divider></v-divider>
          </v-card-text>
          <v-card-text class="profilBadge">
            <v-avatar v-if="avatarUrlCollaborator != null" ref="avatar" size="96" color="" class="elevation-10">
              <v-img :src="avatarUrlCollaborator"> </v-img>
            </v-avatar>
            <v-avatar ref="avatar" size="96" color="EoleBlue" v-else>
              <span class="white--text text-h3">{{ user.firstname[0] + user.lastname[0] }}</span>
            </v-avatar>
          </v-card-text>
          <v-card-text class="profilTabs mb-15">
            <v-row>
              <v-col class="text-left" cols="12" md="10" sm="10" lg="10">
                <v-tabs
                  class="mt-5"
                  color="EoleYellow"
                  v-show="!waitTimeout"
                  v-model="tab"
                  style="box-shadow: none !important"
                  background-color="transparent"
                >
                  <v-tab href="#tab-1">
                    Demandes de rappel
                    <!-- <v-icon>mdi-phone</v-icon> -->
                  </v-tab>

                  <v-tab href="#tab-2">
                    Historique des demandes traitées
                    <!-- <v-icon>mdi-calendar-refresh</v-icon> -->
                  </v-tab>
                  <v-tab href="#tab-3">
                    Historique des demandes envoyées
                    <!-- <v-icon>mdi-calendar-refresh</v-icon> -->
                  </v-tab>
                  <v-tab href="#tab-4">
                    Statistiques
                    <!-- <v-icon>mdi-calendar-refresh</v-icon> -->
                  </v-tab>
                </v-tabs>
              </v-col>
              <v-col cols="12" md="2" sm="2" lg="2" align-self="center">
                <v-dialog v-model="dialog" max-width="1200" :persistent="loaderContact">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" v-bind="attrs" v-on="on" fab icon class="mr-5">
                      <v-icon>fa-plus</v-icon>
                    </v-btn>
                  </template>
                  <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                    <v-card-text style="background-color: #f1f4f9">
                      <v-row>
                        <v-col cols="12" md="3" class="no-padding ml-n2">
                          <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                            <v-card-title>
                              <v-img
                                :src="require('@/assets/images/logos/anavel_logo.png')"
                                max-height="150px"
                                max-width="120px"
                                alt="logo"
                                contain
                              ></v-img>
                            </v-card-title>
                            <v-card-title class="text-center justify-center muller-capitalized"
                              >Mes Services Assurance</v-card-title
                            >
                            <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                            <v-card-text class="no-padding">
                              <v-img
                                :src="require('@/assets/images/logos/layer.png')"
                                alt="logo"
                                width="300px"
                                class=""
                              >
                              </v-img>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="12" md="9" class="no-padding d-flex">
                          <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat :loading="loaderContact">
                            <v-card-title>
                              <span class="text-h5">Effectuer une nouvelle demande de rappel</span>
                            </v-card-title>
                            <v-card-text>
                              <v-stepper v-model="e1" flat>
                                <v-stepper-items>
                                  <v-stepper-content :step="1" class="text-center transparent-bg">
                                    <v-card-subtitle>Quelle est la typologie de votre client ? </v-card-subtitle>
                                    <v-row class="text-center align-center d-inline-flex row-study mb-5">
                                      <v-col
                                        cols="12"
                                        md="6"
                                        v-for="item in clientTypologyProposed"
                                        v-bind:key="item.name"
                                      >
                                        <v-card
                                          @mouseover="item.hovered = true"
                                          @mouseleave="item.hovered = false"
                                          v-on:click="
                                            clientTypologie = item.value
                                            e1 = item.e1
                                          "
                                          outlined
                                          width="200"
                                          height="200"
                                          elevation="3"
                                          class="align-center d-flex rounded-xl"
                                        >
                                          <v-card-text class="text-center pa-5">
                                            <i
                                              :class="[
                                                'mt-4 fa-solid ',
                                                item.icon,
                                                'fa-5x',
                                                item.hovered ? 'EoleYellow--text' : 'EoleBlue--text',
                                              ]"
                                            ></i>
                                            <p class="mt-2">{{ item.name }}</p>
                                          </v-card-text>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-stepper-content>

                                  <v-stepper-content step="2">
                                    <v-card-text v-if="!!clientTypologie && clientTypologie === 1">
                                      <v-container>
                                        <v-form ref="formContact" v-model="validityFormContact" lazy-validation>
                                          <v-row>
                                            <v-col cols="12" sm="6" md="4">
                                              <v-select
                                                dense
                                                :rules="genderRules"
                                                prepend-inner-icon="mdi-account"
                                                outlined
                                                :items="['M.', 'Mme', 'Mlle']"
                                                label="Genre"
                                                v-model="editedItem.gender"
                                                required
                                              >
                                              </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4">
                                              <v-text-field
                                                dense
                                                :rules="lastnameRules"
                                                prepend-inner-icon="mdi-account"
                                                outlined
                                                label="Nom"
                                                v-model="editedItem.lastName"
                                                required
                                              >
                                              </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4">
                                              <v-text-field
                                                dense
                                                :rules="firstnameRules"
                                                prepend-inner-icon="mdi-account"
                                                outlined
                                                label="Prénom"
                                                v-model="editedItem.firstName"
                                                required
                                              >
                                              </v-text-field>
                                            </v-col>
                                            <v-col cols="6" class="mt-n5">
                                              <v-text-field
                                                dense
                                                @input="onPaste"
                                                :rules="phoneRules"
                                                counter="10"
                                                prepend-inner-icon="mdi-phone"
                                                outlined
                                                label="Numéro de téléphone"
                                                v-model="editedItem.phoneNumber"
                                                required
                                              >
                                              </v-text-field>
                                            </v-col>
                                            <v-col cols="6"> </v-col>
                                            <v-col cols="6" class="mt-n5">
                                              <v-text-field
                                                dense
                                                prepend-inner-icon="mdi-email"
                                                outlined
                                                label="Email"
                                                v-model="editedItem.email"
                                                required
                                              >
                                              </v-text-field>
                                            </v-col>
                                            <v-col cols="6" class="mt-n5">
                                              <v-text-field
                                                dense
                                                counter="8"
                                                prepend-inner-icon="mdi-identifier"
                                                outlined
                                                label="ID GRC"
                                                v-model="editedItem.idGRC"
                                                required
                                              >
                                              </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" class="mt-n5">
                                              <v-select
                                                dense
                                                :rules="agencyRules"
                                                prepend-inner-icon="mdi-office-building-marker"
                                                outlined
                                                :items="['Combourg', 'Rennes', 'St Grégoire']"
                                                label="Agence"
                                                v-model="editedItem.agence"
                                                required
                                              >
                                              </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="6" class="mt-n5">
                                              <v-select
                                                dense
                                                @change="func"
                                                :rules="collaboratorRules"
                                                prepend-inner-icon="mdi-account-tie"
                                                outlined
                                                :items="collaboratorsAvailable"
                                                item-text="completName"
                                                label="Attribuer à"
                                                v-model="editedItem.id"
                                                item-value="id"
                                                required
                                              >
                                                <template v-slot:item="{ item }">
                                                  <v-list-item-avatar size="24" v-if="item.avatarUrl != null">
                                                    <v-img :src="item.avatarUrl"></v-img>
                                                  </v-list-item-avatar>
                                                  <v-list-item-avatar size="24" v-else color="EoleBlue">
                                                    <span
                                                      class="white--text text-center ml-1"
                                                      style="font-size: 0.7rem"
                                                      >{{ item.firstname[0] + item.lastname[0] }}</span
                                                    >
                                                  </v-list-item-avatar>
                                                  <v-list-item-content>
                                                    <v-list-item-title v-html="item.completName">{{
                                                      item
                                                    }}</v-list-item-title>
                                                  </v-list-item-content>
                                                  <v-list-item-action>
                                                    <v-chip
                                                      class="ma-3"
                                                      v-if="item.solicitationStatus === 1"
                                                      color="EoleErrorBase"
                                                      text-color="white"
                                                      small
                                                    >
                                                      Très solicité</v-chip
                                                    >
                                                    <v-chip
                                                      class="ma-3"
                                                      v-else-if="item.solicitationStatus === 2"
                                                      color="EoleYellow"
                                                      small
                                                      >Solicité</v-chip
                                                    >
                                                  </v-list-item-action>
                                                </template>
                                              </v-select>
                                            </v-col>
                                            <v-col cols="12" class="mt-n5">
                                              <!-- <v-textarea
                                                dense
                                                :rules="reasonRules"
                                                prepend-inner-icon="mdi-message-reply-text"
                                                outlined
                                                label="Motif de l'appel"
                                                v-model="editedItem.reason"
                                                required
                                              >
                                              </v-textarea> -->
                                              <vue2-tinymce-editor v-model="editedItem.reason"></vue2-tinymce-editor>
                                            </v-col>
                                          </v-row>
                                        </v-form>
                                      </v-container>
                                    </v-card-text>
                                    <v-card-text v-if="!!clientTypologie && clientTypologie === 2">
                                      <v-container>
                                        <v-form ref="formContactPro" v-model="validityFormContactPro" lazy-validation>
                                          <v-row>
                                            <v-col cols="12" sm="6" md="12">
                                              <v-text-field
                                                dense
                                                prepend-inner-icon="mdi-account"
                                                outlined
                                                label="Raison sociale"
                                                v-model="editedItem.lastName"
                                                required
                                              >
                                              </v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                              <v-text-field
                                                dense
                                                @input="onPaste"
                                                :rules="phoneRules"
                                                counter="10"
                                                prepend-inner-icon="mdi-phone"
                                                outlined
                                                label="Numéro de téléphone"
                                                v-model="editedItem.phoneNumber"
                                                required
                                              >
                                              </v-text-field>
                                            </v-col>
                                            <v-col cols="6"> </v-col>
                                            <v-col cols="6">
                                              <v-text-field
                                                dense
                                                prepend-inner-icon="mdi-email"
                                                outlined
                                                label="Email"
                                                v-model="editedItem.email"
                                                required
                                              >
                                              </v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                              <v-text-field
                                                dense
                                                counter="8"
                                                prepend-inner-icon="mdi-identifier"
                                                outlined
                                                label="ID GRC"
                                                v-model="editedItem.idGRC"
                                                required
                                              >
                                              </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                              <v-select
                                                dense
                                                :rules="agencyRules"
                                                prepend-inner-icon="mdi-office-building-marker"
                                                outlined
                                                :items="['Combourg', 'Rennes', 'St Grégoire']"
                                                label="Agence"
                                                v-model="editedItem.agence"
                                                required
                                              >
                                              </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                              <v-select
                                                dense
                                                @change="func"
                                                :rules="collaboratorRules"
                                                prepend-inner-icon="mdi-account-tie"
                                                outlined
                                                :items="collaboratorsAvailable"
                                                item-text="completName"
                                                label="Attribuer à"
                                                v-model="editedItem.id"
                                                item-value="id"
                                                required
                                              >
                                                <template v-slot:item="{ item }">
                                                  <v-list-item-avatar size="24">
                                                    <v-img :src="item.avatarUrl"></v-img>
                                                  </v-list-item-avatar>
                                                  <v-list-item-content>
                                                    <v-list-item-title v-html="item.completName"></v-list-item-title>
                                                  </v-list-item-content>
                                                </template>
                                              </v-select>
                                            </v-col>
                                            <v-col cols="12">
                                              <v-textarea
                                                dense
                                                :rules="reasonRules"
                                                prepend-inner-icon="mdi-message-reply-text"
                                                outlined
                                                label="Motif de l'appel"
                                                v-model="editedItem.reason"
                                                required
                                              >
                                              </v-textarea>
                                            </v-col>
                                          </v-row>
                                        </v-form>
                                      </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                      <!-- <v-btn color="EoleYellow" text @click="close"> Annuler </v-btn> -->
                                      <v-btn v-if="e1 > 0" text @click="e1--" :disabled="loaderContact"> retour </v-btn>

                                      <v-spacer></v-spacer>

                                      <v-btn
                                        text
                                        @click="editedIndex === -1 && validateFormContact(editedItem.id)"
                                        :loading="loaderContact"
                                      >
                                        Terminer
                                      </v-btn>
                                    </v-card-actions>
                                  </v-stepper-content>
                                </v-stepper-items>
                              </v-stepper>
                            </v-card-text>
                            <v-spacer></v-spacer>
                            <!-- <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-on="on"
                                v-bind="attrs"
                                :disabled="calculateDay - numDays < 0"
                                color="EoleGreen"
                                class="white--text"
                                @click="postHoliday"
                                >Confirmer ma demande</v-btn
                              >
                            </template>
                            <span>Cliquez-ici pour valider votre demande de congé</span>
                          </v-tooltip>
                        </v-card-actions> -->
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="mt-10">
            <v-tabs-items v-model="tab" style="background-color: transparent !important">
              <v-tab-item :value="'tab-1'" class="mb-5">
                <v-row v-if="contact.length > 0">
                  <v-col class="d-flex" cols="12" md="4" v-for="(call, index) in contact" :key="index">
                    <v-card outlined elevation="3" class="text-center flex d-flex flex-column rounded-xl call-card">
                      <v-row class="pa-5">
                        <v-col class="text-left" cols="12" md="9">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on" color="EoleBlue">
                                <v-icon>fa-circle-info</v-icon>
                              </v-btn>
                            </template>
                            <span>
                              Demande créée le {{ call.datetime }} <br />
                              par {{ call.attributor.lastname }} {{ call.attributor.firstname }} <br />
                            </span>
                          </v-tooltip>
                        </v-col>
                        <v-col class="text-right" cols="12" md="3">
                          <v-menu right transition="slide-x-transition" offset-x>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>

                            <v-list dense>
                              <v-subheader>Mes actions disponibles</v-subheader>

                              <v-list-item link color="EoleBlue">
                                <v-dialog transition="slide-x-transition" max-width="1200">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title v-bind="attrs" v-on="on"> Assurer le suivi </v-list-item-title>
                                    <v-list-item-action>
                                      <i class="fa-solid fa-circle-info ml-2"></i>
                                    </v-list-item-action>
                                  </template>
                                  <template v-slot:default="dialogTrace">
                                    <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                                      <v-card-text style="background-color: #f1f4f9">
                                        <v-row>
                                          <v-col cols="12" md="3" class="no-padding ml-n2">
                                            <v-card
                                              class="rounded-l-xl"
                                              color="#f1f4f9"
                                              style="background-color: #f1f4f9"
                                              flat
                                            >
                                              <v-card-title>
                                                <v-img
                                                  :src="require('@/assets/images/logos/anavel_logo.png')"
                                                  max-height="150px"
                                                  max-width="120px"
                                                  alt="logo"
                                                  contain
                                                ></v-img>
                                              </v-card-title>
                                              <v-card-title class="text-center justify-center muller-capitalized"
                                                >Mes Services Assurance</v-card-title
                                              >
                                              <v-card-subtitle class="text-center justify-center"
                                                >Un logiciel ANAVEL</v-card-subtitle
                                              >
                                              <v-card-text class="no-padding">
                                                <v-img
                                                  :src="require('@/assets/images/logos/layer.png')"
                                                  alt="logo"
                                                  width="300px"
                                                  class=""
                                                >
                                                </v-img>
                                              </v-card-text>
                                            </v-card>
                                          </v-col>
                                          <v-col cols="12" md="9" class="no-padding d-flex">
                                            <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                                              <v-card-title class="text-h5">
                                                <v-row>
                                                  <v-col cols="12" md="10" class="text-left">Suivi de la demande</v-col>
                                                  <v-col cols="12" md="2"> </v-col>
                                                </v-row>
                                              </v-card-title>

                                              <v-card-text class="timeline-container"
                                                ><v-timeline dense clipped>
                                                  <v-timeline-item
                                                    fill-dot
                                                    class="white--text mb-12"
                                                    color="EoleBlue"
                                                    large
                                                  >
                                                    <template v-slot:icon>
                                                      <v-avatar
                                                        v-if="avatarUrlCollaborator != null"
                                                        ref="avatar"
                                                        size="48"
                                                        color=""
                                                        class="elevation-10"
                                                      >
                                                        <v-img :src="avatarUrlCollaborator"> </v-img>
                                                      </v-avatar>
                                                      <v-avatar ref="avatar" size="48" color="EoleBlue" v-else>
                                                        <span class="white--text text-h5">{{
                                                          $store.state.user.firstname[0] + $store.state.user.lastname[0]
                                                        }}</span>
                                                      </v-avatar>
                                                    </template>
                                                    <v-textarea
                                                      no-resize
                                                      v-model="traceComment"
                                                      hide-details
                                                      flat
                                                      outlined
                                                      label="Laisser un commentaire"
                                                    >
                                                      <template v-slot:append>
                                                        <v-btn
                                                          :disabled="traceComment === ''"
                                                          class="mx-0"
                                                          depressed
                                                          @click="postComment(call, traceComment)"
                                                        >
                                                          Ajouter
                                                        </v-btn>
                                                      </template>
                                                    </v-textarea>
                                                  </v-timeline-item>
                                                  <v-timeline-item
                                                    v-for="(log, index) in reverseTrace(call.trace)"
                                                    :key="index"
                                                    color="EoleBlue"
                                                  >
                                                    <template v-slot:icon>
                                                      <i
                                                        v-if="log.id_action == 3"
                                                        class="fa-solid fa-share-from-square white--text"
                                                      ></i>
                                                      <i
                                                        v-else-if="log.id_action == 2"
                                                        class="fa-solid fa-comment white--text"
                                                      ></i>
                                                    </template>
                                                    <v-row justify="space-between">
                                                      <v-col cols="7">
                                                        <span class="font-weight-bold">Action: </span
                                                        >{{ log.action || "Non spécifiée" }}<br />
                                                        <span v-if="log.comments">
                                                          <span class="font-weight-bold">Commentaires: </span
                                                          >{{ log.comments }}
                                                        </span>
                                                        <span v-else> Pas de commentaire </span><br />
                                                        <span>
                                                          <span class="font-weight-bold">Action réalisée par: </span
                                                          >{{ log.firstname }}
                                                        </span>
                                                      </v-col>
                                                    </v-row>

                                                    <template>
                                                      {{ log.datetime }}
                                                    </template>
                                                  </v-timeline-item>

                                                  <v-timeline-item color="EoleGreen">
                                                    <template v-slot:icon>
                                                      <i class="fa-solid fa-folder-open white--text"></i>
                                                    </template>
                                                    <v-row justify="space-between">
                                                      <v-col cols="7">
                                                        <span class="font-weight-bold">Action: </span>Ouverture de la
                                                        demande #{{ call.idContact }}<br />
                                                      </v-col>
                                                    </v-row>

                                                    <template>
                                                      {{ call.datetime }}
                                                    </template>
                                                  </v-timeline-item>
                                                </v-timeline>
                                              </v-card-text>
                                              <v-spacer></v-spacer>
                                            </v-card>
                                          </v-col>
                                        </v-row>
                                      </v-card-text>
                                    </v-card>
                                  </template>
                                </v-dialog>
                              </v-list-item>
                              <v-list-item link color="EoleBlue" :disabled="transferInProgress">
                                <v-dialog
                                  transition="slide-x-transition"
                                  max-width="1200"
                                  v-model="dialogTransferContact"
                                  :persistent="transferInProgress"
                                  close-on-content-click
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title v-bind="attrs" v-on="on">Assigner à nouveau</v-list-item-title>
                                    <v-list-item-action>
                                      <i class="fa-solid fa-share-nodes ml-2"></i>
                                    </v-list-item-action>
                                  </template>
                                  <template v-slot:default="dialogContact">
                                    <v-card
                                      class="rounded-l-xl"
                                      style="box-shadow: 0 0 60px -20px #000000d9"
                                      min-height="800px"
                                      max-height="800px"
                                    >
                                      <v-card-text style="background-color: #f1f4f9">
                                        <v-row>
                                          <v-col cols="12" md="3" class="no-padding ml-n2">
                                            <v-card
                                              min-height="800px"
                                              max-height="800px"
                                              class="rounded-l-xl"
                                              color="#f1f4f9"
                                              style="background-color: #f1f4f9"
                                              flat
                                            >
                                              <v-card-title>
                                                <v-img
                                                  :src="require('@/assets/images/logos/anavel_logo.png')"
                                                  max-height="150px"
                                                  max-width="120px"
                                                  alt="logo"
                                                  contain
                                                ></v-img>
                                              </v-card-title>
                                              <v-card-title class="text-center justify-center muller-capitalized"
                                                >Mes Services Assurance</v-card-title
                                              >
                                              <v-card-subtitle class="text-center justify-center"
                                                >Un logiciel ANAVEL</v-card-subtitle
                                              >
                                              <v-card-text class="no-padding">
                                                <v-img
                                                  :src="require('@/assets/images/logos/layer.png')"
                                                  alt="logo"
                                                  width="300px"
                                                  class=""
                                                >
                                                </v-img>
                                              </v-card-text>
                                            </v-card>
                                          </v-col>
                                          <v-col cols="12" md="9" class="no-padding d-flex">
                                            <v-card
                                              class="rounded-r-xl flex-fill flex d-flex flex-column"
                                              flat
                                              :loading="transferInProgress"
                                            >
                                              <v-card-title class="text-h5">
                                                <v-row>
                                                  <v-col cols="12" md="10" class="text-left"
                                                    >Changement d'assignation</v-col
                                                  >
                                                  <v-col cols="12" md="2">
                                                    <v-chip>Appel n°{{ call.idContact }}</v-chip>
                                                  </v-col>
                                                </v-row>
                                              </v-card-title>
                                              <v-card-subtitle
                                                >Sélectionnez un collaborateur dans la liste pour changer l'assignation
                                                de cette demande</v-card-subtitle
                                              >
                                              <v-card-text class="mb-n5">
                                                <v-row>
                                                  <v-col cols="12" md="12">
                                                    <v-text-field
                                                      dense
                                                      prepend-inner-icon="mdi-account-search"
                                                      outlined
                                                      label="Rechercher un collaborateur"
                                                      v-model="searchCollaborator"
                                                      @input="searchCollaborators"
                                                    ></v-text-field>
                                                  </v-col>
                                                </v-row>
                                              </v-card-text>
                                              <v-card-text class="no-padding">
                                                <v-list dense :disabled="transferInProgress">
                                                  <v-list-item
                                                    v-for="(collaborator, index) in collaboratorFiltering(
                                                      collaboratorsAvailable,
                                                    )"
                                                    :key="index"
                                                    :disabled="transferInProgress"
                                                    color="EoleBlue"
                                                    link
                                                    @click="
                                                      transferContact(
                                                        call.idContact,
                                                        collaborator.id,
                                                        collaborator.firstname,
                                                        collaborator.email,
                                                      )
                                                    "
                                                  >
                                                    <v-badge
                                                      :color="collaborator.available === 0 ? 'EoleYellow' : 'EoleGreen'"
                                                      bordered
                                                      dot
                                                      bottom
                                                      overlap
                                                      offset-x="25"
                                                      offset-y="15"
                                                    >
                                                      <v-list-item-avatar
                                                        v-if="collaborator.avatarUrl != null"
                                                        class="elevation-10"
                                                      >
                                                        <v-img :src="collaborator.avatarUrl"></v-img>
                                                      </v-list-item-avatar>
                                                      <v-list-item-avatar v-else color="EoleBlue" class="text-center">
                                                        <v-avatar color="red">
                                                          <span class="white--text mr-2">{{
                                                            collaborator.firstname[0] + collaborator.lastname[0]
                                                          }}</span>
                                                        </v-avatar>
                                                      </v-list-item-avatar>
                                                    </v-badge>
                                                    <!-- <v-list-item-avatar
                                                      v-if="collaborator.avatarUrl != null"
                                                      class="elevation-10"
                                                    >
                                                      <v-img :src="collaborator.avatarUrl"></v-img>
                                                    </v-list-item-avatar>
                                                    <v-list-item-avatar v-else color="EoleBlue" class="text-center">
                                                      <v-avatar color="red">
                                                        <span class="white--text mr-2">{{
                                                          collaborator.firstname[0] + collaborator.lastname[0]
                                                        }}</span>
                                                      </v-avatar>
                                                    </v-list-item-avatar> -->
                                                    <v-list-item-content>
                                                      <v-list-item-title>{{
                                                        collaborator.completName
                                                      }}</v-list-item-title>

                                                      <!-- <v-list-item-subtitle>
                                                        <v-sheet v-bind="attrs" v-on="on">
                                                          <apexchart
                                                            v-if="collaborator.last_5_days_contact"
                                                            type="line"
                                                            height="100"
                                                            :options="getChartOptions(collaborator)"
                                                            :series="getChartSeries(collaborator)"
                                                          ></apexchart>
                                                        </v-sheet>
                                                      </v-list-item-subtitle> -->
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                      <v-chip
                                                        v-if="collaborator.solicitationStatus === 1"
                                                        color="EoleErrorBase"
                                                        text-color="white"
                                                        small
                                                      >
                                                        Très solicité
                                                      </v-chip>
                                                      <v-chip
                                                        v-else-if="collaborator.solicitationStatus === 2"
                                                        color="EoleYellow"
                                                        small
                                                      >
                                                        Solicité
                                                      </v-chip>
                                                    </v-list-item-action>
                                                  </v-list-item>
                                                </v-list>
                                              </v-card-text>
                                              <v-spacer></v-spacer>
                                            </v-card>
                                          </v-col>
                                        </v-row>
                                      </v-card-text>
                                    </v-card>
                                  </template>
                                </v-dialog>
                              </v-list-item>

                              <v-list-item link :disabled="transferInProgress" @click="prepareOutlookMail(call)">
                                <v-list-item-title> Envoyer par email </v-list-item-title>

                                <v-list-item-action>
                                  <svg
                                    class="ml-2"
                                    version="1.1"
                                    id="Livello_1"
                                    xmlns:x="http://ns.adobe.com/Extensibility/1.0/"
                                    xmlns:i="http://ns.adobe.com/AdobeIllustrator/10.0/"
                                    xmlns:graph="http://ns.adobe.com/Graphs/1.0/"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 1831.085 1703.335"
                                    enable-background="new 0 0 1831.085 1703.335"
                                    xml:space="preserve"
                                    width="20"
                                    height="20"
                                  >
                                    <path
                                      fill="#0A2767"
                                      d="M1831.083,894.25c0.1-14.318-7.298-27.644-19.503-35.131h-0.213l-0.767-0.426l-634.492-375.585  c-2.74-1.851-5.583-3.543-8.517-5.067c-24.498-12.639-53.599-12.639-78.098,0c-2.934,1.525-5.777,3.216-8.517,5.067L446.486,858.693  l-0.766,0.426c-19.392,12.059-25.337,37.556-13.278,56.948c3.553,5.714,8.447,10.474,14.257,13.868l634.492,375.585  c2.749,1.835,5.592,3.527,8.517,5.068c24.498,12.639,53.599,12.639,78.098,0c2.925-1.541,5.767-3.232,8.517-5.068l634.492-375.585  C1823.49,922.545,1831.228,908.923,1831.083,894.25z"
                                    />
                                    <path
                                      fill="#0364B8"
                                      d="M520.453,643.477h416.38v381.674h-416.38V643.477z M1745.917,255.5V80.908  c1-43.652-33.552-79.862-77.203-80.908H588.204C544.552,1.046,510,37.256,511,80.908V255.5l638.75,170.333L1745.917,255.5z"
                                    />
                                    <path fill="#0078D4" d="M511,255.5h425.833v383.25H511V255.5z" />
                                    <path
                                      fill="#28A8EA"
                                      d="M1362.667,255.5H936.833v383.25L1362.667,1022h383.25V638.75L1362.667,255.5z"
                                    />
                                    <path fill="#0078D4" d="M936.833,638.75h425.833V1022H936.833V638.75z" />
                                    <path fill="#0364B8" d="M936.833,1022h425.833v383.25H936.833V1022z" />
                                    <path fill="#14447D" d="M520.453,1025.151h416.38v346.969h-416.38V1025.151z" />
                                    <path fill="#0078D4" d="M1362.667,1022h383.25v383.25h-383.25V1022z" />
                                    <linearGradient
                                      id="SVGID_1_"
                                      gradientUnits="userSpaceOnUse"
                                      x1="1128.4584"
                                      y1="811.0833"
                                      x2="1128.4584"
                                      y2="1.9982"
                                      gradientTransform="matrix(1 0 0 -1 0 1705.3334)"
                                    >
                                      <stop offset="0" style="stop-color: #35b8f1" />
                                      <stop offset="1" style="stop-color: #28a8ea" />
                                    </linearGradient>
                                    <path
                                      fill="url(#SVGID_1_)"
                                      d="M1811.58,927.593l-0.809,0.426l-634.492,356.848c-2.768,1.703-5.578,3.321-8.517,4.769  c-10.777,5.132-22.481,8.029-34.407,8.517l-34.663-20.27c-2.929-1.47-5.773-3.105-8.517-4.897L447.167,906.003h-0.298  l-21.036-11.753v722.384c0.328,48.196,39.653,87.006,87.849,86.7h1230.914c0.724,0,1.363-0.341,2.129-0.341  c10.18-0.651,20.216-2.745,29.808-6.217c4.145-1.756,8.146-3.835,11.966-6.217c2.853-1.618,7.75-5.152,7.75-5.152  c21.814-16.142,34.726-41.635,34.833-68.772V894.25C1831.068,908.067,1823.616,920.807,1811.58,927.593z"
                                    />
                                    <path
                                      opacity="0.5"
                                      fill="#0A2767"
                                      enable-background="new    "
                                      d="M1797.017,891.397v44.287l-663.448,456.791L446.699,906.301  c0-0.235-0.191-0.426-0.426-0.426l0,0l-63.023-37.899v-31.938l25.976-0.426l54.932,31.512l1.277,0.426l4.684,2.981  c0,0,645.563,368.346,647.267,369.197l24.698,14.478c2.129-0.852,4.258-1.703,6.813-2.555  c1.278-0.852,640.879-360.681,640.879-360.681L1797.017,891.397z"
                                    />
                                    <path
                                      fill="#1490DF"
                                      d="M1811.58,927.593l-0.809,0.468l-634.492,356.848c-2.768,1.703-5.578,3.321-8.517,4.769  c-24.641,12.038-53.457,12.038-78.098,0c-2.918-1.445-5.76-3.037-8.517-4.769L446.657,928.061l-0.766-0.468  c-12.25-6.642-19.93-19.409-20.057-33.343v722.384c0.305,48.188,39.616,87.004,87.803,86.7c0.001,0,0.002,0,0.004,0h1229.636  c48.188,0.307,87.5-38.509,87.807-86.696c0-0.001,0-0.002,0-0.004V894.25C1831.068,908.067,1823.616,920.807,1811.58,927.593z"
                                    />
                                    <path
                                      opacity="0.1"
                                      enable-background="new    "
                                      d="M1185.52,1279.629l-9.496,5.323c-2.752,1.752-5.595,3.359-8.517,4.812  c-10.462,5.135-21.838,8.146-33.47,8.857l241.405,285.479l421.107,101.476c11.539-8.716,20.717-20.178,26.7-33.343L1185.52,1279.629  z"
                                    />
                                    <path
                                      opacity="0.05"
                                      enable-background="new    "
                                      d="M1228.529,1255.442l-52.505,29.51c-2.752,1.752-5.595,3.359-8.517,4.812  c-10.462,5.135-21.838,8.146-33.47,8.857l113.101,311.838l549.538,74.989c21.649-16.254,34.394-41.743,34.407-68.815v-9.326  L1228.529,1255.442z"
                                    />
                                    <path
                                      fill="#28A8EA"
                                      d="M514.833,1703.333h1228.316c18.901,0.096,37.335-5.874,52.59-17.033l-697.089-408.331  c-2.929-1.47-5.773-3.105-8.517-4.897L447.125,906.088h-0.298l-20.993-11.838v719.914  C425.786,1663.364,465.632,1703.286,514.833,1703.333C514.832,1703.333,514.832,1703.333,514.833,1703.333z"
                                    />
                                    <path
                                      opacity="0.1"
                                      enable-background="new    "
                                      d="M1022,418.722v908.303c-0.076,31.846-19.44,60.471-48.971,72.392  c-9.148,3.931-19,5.96-28.957,5.962H425.833V383.25H511v-42.583h433.073C987.092,340.83,1021.907,375.702,1022,418.722z"
                                    />
                                    <path
                                      opacity="0.2"
                                      enable-background="new    "
                                      d="M979.417,461.305v908.302c0.107,10.287-2.074,20.469-6.388,29.808  c-11.826,29.149-40.083,48.273-71.54,48.417H425.833V383.25h475.656c12.356-0.124,24.533,2.958,35.344,8.943  C962.937,405.344,979.407,432.076,979.417,461.305z"
                                    />
                                    <path
                                      opacity="0.2"
                                      enable-background="new    "
                                      d="M979.417,461.305v823.136c-0.208,43-34.928,77.853-77.927,78.225H425.833V383.25  h475.656c12.356-0.124,24.533,2.958,35.344,8.943C962.937,405.344,979.407,432.076,979.417,461.305z"
                                    />
                                    <path
                                      opacity="0.2"
                                      enable-background="new    "
                                      d="M936.833,461.305v823.136c-0.046,43.067-34.861,78.015-77.927,78.225H425.833  V383.25h433.072c43.062,0.023,77.951,34.951,77.927,78.013C936.833,461.277,936.833,461.291,936.833,461.305z"
                                    />
                                    <linearGradient
                                      id="SVGID_2_"
                                      gradientUnits="userSpaceOnUse"
                                      x1="162.7469"
                                      y1="1383.0741"
                                      x2="774.0864"
                                      y2="324.2592"
                                      gradientTransform="matrix(1 0 0 -1 0 1705.3334)"
                                    >
                                      <stop offset="0" style="stop-color: #1784d9" />
                                      <stop offset="0.5" style="stop-color: #107ad5" />
                                      <stop offset="1" style="stop-color: #0a63c9" />
                                    </linearGradient>
                                    <path
                                      fill="url(#SVGID_2_)"
                                      d="M78.055,383.25h780.723c43.109,0,78.055,34.947,78.055,78.055v780.723  c0,43.109-34.946,78.055-78.055,78.055H78.055c-43.109,0-78.055-34.947-78.055-78.055V461.305  C0,418.197,34.947,383.25,78.055,383.25z"
                                    />
                                    <path
                                      fill="#FFFFFF"
                                      d="M243.96,710.631c19.238-40.988,50.29-75.289,89.17-98.495c43.057-24.651,92.081-36.94,141.675-35.515  c45.965-0.997,91.321,10.655,131.114,33.683c37.414,22.312,67.547,55.004,86.742,94.109c20.904,43.09,31.322,90.512,30.405,138.396  c1.013,50.043-9.706,99.628-31.299,144.783c-19.652,40.503-50.741,74.36-89.425,97.388c-41.327,23.734-88.367,35.692-136.011,34.578  c-46.947,1.133-93.303-10.651-134.01-34.067c-37.738-22.341-68.249-55.07-87.892-94.28c-21.028-42.467-31.57-89.355-30.745-136.735  C212.808,804.859,223.158,755.686,243.96,710.631z M339.006,941.858c10.257,25.912,27.651,48.385,50.163,64.812  c22.93,16.026,50.387,24.294,78.353,23.591c29.783,1.178,59.14-7.372,83.634-24.358c22.227-16.375,39.164-38.909,48.715-64.812  c10.677-28.928,15.946-59.572,15.543-90.404c0.33-31.127-4.623-62.084-14.649-91.554c-8.855-26.607-25.246-50.069-47.182-67.537  c-23.88-17.79-53.158-26.813-82.91-25.55c-28.572-0.74-56.644,7.593-80.184,23.804c-22.893,16.496-40.617,39.168-51.1,65.365  c-23.255,60.049-23.376,126.595-0.341,186.728L339.006,941.858z"
                                    />
                                    <path fill="#50D9FF" d="M1362.667,255.5h383.25v383.25h-383.25V255.5z" />
                                  </svg>
                                </v-list-item-action>
                              </v-list-item>

                              <v-divider class="my-2"></v-divider>
                              <v-dialog transition="dialog-bottom-transition" max-width="400">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-list-item
                                    v-bind="attrs"
                                    v-on="on"
                                    color="EoleError"
                                    link
                                    @click="archiveContact(call.idContact)"
                                  >
                                    <v-list-item-title> Demande traitée</v-list-item-title>
                                    <v-list-item-action>
                                      <i class="fa-solid fa-circle-check ml-2 EoleGreen--text"></i>
                                    </v-list-item-action>
                                  </v-list-item>
                                </template>
                                <template v-slot:default="dialogArchiveContact">
                                  <v-card>
                                    <v-card-text class="text-center">
                                      <v-img
                                        src="https://anavel.bzh/assets/success.gif"
                                        max-width="126px"
                                        class="mx-auto"
                                      ></v-img>
                                      Votre demande à été traitée, il ne vous reste plus que
                                      <strong> {{ contact.length - 1 }} appels</strong> !
                                    </v-card-text>
                                  </v-card>
                                </template>
                              </v-dialog>
                            </v-list>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-card-text class="flex-grow-1">
                        <v-row class="flex-column">
                          <v-col class="text-right">
                            <v-chip color="EoleYellow" small>Agence de {{ call.agency }}</v-chip>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field
                              dense
                              :id="`identity-${index}`"
                              outlined
                              label="Identité"
                              append-icon="mdi-content-copy"
                              readonly
                              prepend-inner-icon="mdi-account"
                              :value="call.lastname + ' ' + call.firstname"
                              @click:append="copyClipboard(`identity-${index}`)"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="8">
                            <v-text-field
                              dense
                              :id="`email-${index}`"
                              outlined
                              label="Email"
                              append-icon="mdi-content-copy"
                              readonly
                              prepend-inner-icon="mdi-email-outline"
                              :value="call.email"
                              @click:append="copyClipboard(`email-${index}`)"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-btn
                              :disabled="call.email === null"
                              class="white--text"
                              :href="`mailto:${call.email}`"
                              color="EoleBlue"
                              >Contacter</v-btn
                            >
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="8">
                            <v-text-field
                              dense
                              :id="`phone-${index}`"
                              outlined
                              label="Numéro de téléphone"
                              append-icon="mdi-content-copy"
                              readonly
                              prepend-inner-icon="mdi-phone"
                              :value="call.phoneNumber"
                              @click:append="copyClipboard(`phone-${index}`)"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-btn class="white--text" :href="`tel:${call.phoneNumber}`" color="EoleBlue"
                              >Appeler</v-btn
                            >
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field
                              dense
                              :id="`idGRC-${index}`"
                              outlined
                              label="Identifiant GRC"
                              append-icon="mdi-content-copy"
                              readonly
                              prepend-inner-icon="mdi-identifier"
                              :value="call.idGRC"
                              @click:append="copyClipboard('idGRC')"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <!-- <v-textarea
                              dense
                              :id="`reason-${index}`"
                              no-resize
                              type="code"
                              outlined
                              label="Motif de l'appel"
                              readonly
                              :value="call.reason"
                              rows="6"
                              append-icon="mdi-content-copy"
                              @click:append="copyClipboard(`reason-${index}`)"
                            >
                            </v-textarea> -->
                            <v-card outlined class="text-left" min-height="100px">
                              <v-card-text v-html="call.reason"></v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-spacer></v-spacer>
                      <!-- <v-card-actions>
                        <v-row>
                          <v-col v-if="call.attributorName === null">
                            <v-skeleton-loader class="mx-auto" type="text"></v-skeleton-loader>
                          </v-col>

                          <v-col v-else class="text-center" cols="12" md="12"
                            >Demande créée le {{ call.datetime }}</v-col
                          >
                        </v-row>
                      </v-card-actions> -->
                    </v-card>
                  </v-col>
                </v-row>
                <v-row v-else class="text-center justify-center">
                  <v-alert color="transparent">
                    <h2>Bonjour {{ collaborator.firstname }},</h2>

                    <v-img
                      class="mx-auto"
                      max-width="350"
                      :src="require('@/assets/images/pictogram/contact/getting_coffee.png')"
                    ></v-img>
                    Vous n'avez aucune demande de rappel. <br />
                    N'hésitez pas à aller vous chercher un café et à repasser dans quelques instants. 😉
                  </v-alert>
                </v-row>
              </v-tab-item>

              <v-tab-item :value="'tab-2'">
                <v-card-text class="no-padding">
                  <v-row v-if="contactTreated.length > 0">
                    <v-col cols="12">
                      <v-data-table
                        item-key="idContact"
                        class="elevation-0 no-padding"
                        :loading="loading"
                        loading-text="Chargement des produits en cours... Merci de patienter"
                        :page.sync="page"
                        :items-per-page="10"
                        :headers="headersContactTreated"
                        :items="getContactsByFilter()"
                        hide-default-footer
                        @page-count="pageCount = $event"
                        sort-by="calories"
                        :footer-props="{
                          'items-per-page-text': 'produits par page(s)',
                        }"
                      >
                        <template v-slot:top>
                          <v-row>
                            <v-col cols="12" md="4">
                              <v-text-field
                                prepend-icon="mdi-magnify"
                                v-model="searchContactTreated"
                                label="Rechercher parmi vos demandes traitées"
                                class="mx-4"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item.agency="{ item }">
                          <v-chip small color="EoleYellow"> Agence de {{ item.agency }} </v-chip>
                        </template>
                        <template v-slot:item.attributor="{ item }">
                          {{ item.attributor.firstname }} {{ item.attributor.lastname }}
                        </template>
                        <template v-slot:item.firstname="{ item }"> {{ item.lastname }} {{ item.firstname }} </template>
                        <template v-slot:item.actions="{ item }">
                          <v-dialog max-width="1200">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" rounded icon fab>
                                <i class="fa-solid fa-eye"></i>
                              </v-btn>
                            </template>
                            <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                              <v-card-text style="background-color: #f1f4f9">
                                <v-row>
                                  <v-col cols="12" md="3" class="no-padding ml-n2">
                                    <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                                      <v-card-title>
                                        <v-img
                                          :src="require('@/assets/images/logos/anavel_logo.png')"
                                          max-height="150px"
                                          max-width="120px"
                                          alt="logo"
                                          contain
                                        ></v-img>
                                      </v-card-title>
                                      <v-card-title class="text-center justify-center muller-capitalized"
                                        >Mes Services Assurance</v-card-title
                                      >
                                      <v-card-subtitle class="text-center justify-center"
                                        >Un logiciel ANAVEL</v-card-subtitle
                                      >
                                      <v-card-text class="no-padding">
                                        <v-img
                                          :src="require('@/assets/images/logos/layer.png')"
                                          alt="logo"
                                          width="300px"
                                          class=""
                                        >
                                        </v-img>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                  <v-col cols="12" md="9" class="no-padding d-flex">
                                    <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                                      <v-card-title>
                                        <span class="text-h5">Votre demande portant le N°{{ item.idContact }}</span>
                                      </v-card-title>
                                      <v-card-text class="flex-grow-1">
                                        <v-row class="flex-column">
                                          <v-col class="text-left">Demande créée le {{ item.datetime }} </v-col>
                                          <v-col class="text-right">
                                            <v-chip color="EoleYellow" small>Agence de {{ item.agency }}</v-chip>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col>
                                            <v-text-field
                                              dense
                                              id="identity"
                                              outlined
                                              label="Identité"
                                              append-icon="mdi-content-copy"
                                              readonly
                                              prepend-inner-icon="mdi-account"
                                              :value="item.lastname + ' ' + item.firstname"
                                              @click:append="copyClipboard('identity')"
                                            ></v-text-field>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col>
                                            <v-text-field
                                              dense
                                              id="email"
                                              outlined
                                              label="Email"
                                              append-icon="mdi-content-copy"
                                              readonly
                                              prepend-inner-icon="mdi-email"
                                              :value="item.email"
                                              @click:append="copyClipboard('email')"
                                            ></v-text-field>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col cols="12" md="7">
                                            <v-text-field
                                              dense
                                              id="phone"
                                              outlined
                                              label="Numéro de téléphone"
                                              append-icon="mdi-content-copy"
                                              readonly
                                              prepend-inner-icon="mdi-phone"
                                              :value="item.phoneNumber"
                                              @click:append="copyClipboard('phone')"
                                            ></v-text-field>
                                          </v-col>
                                          <v-col class="mt-2" cols="12" md="2">
                                            <v-btn
                                              class="white--text"
                                              :href="`tel:${item.phoneNumber}`"
                                              color="EoleBlue"
                                              >Appeler</v-btn
                                            >
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col>
                                            <v-text-field
                                              dense
                                              id="idGRC"
                                              outlined
                                              label="Identifiant GRC"
                                              append-icon="mdi-content-copy"
                                              readonly
                                              prepend-inner-icon="mdi-identifier"
                                              :value="item.idGRC"
                                              @click:append="copyClipboard('idGRC')"
                                            ></v-text-field>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col>
                                            <v-textarea
                                              dense
                                              id="reason"
                                              no-resize
                                              type="code"
                                              outlined
                                              label="Motif de l'appel"
                                              readonly
                                              :value="item.reason"
                                              rows="1"
                                              auto-grow
                                              append-icon="mdi-content-copy"
                                              @click:append="copyClipboard('reason')"
                                            ></v-textarea>
                                          </v-col>
                                        </v-row>
                                      </v-card-text>
                                      <v-spacer></v-spacer>
                                      <!-- <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-on="on"
                                v-bind="attrs"
                                :disabled="calculateDay - numDays < 0"
                                color="EoleGreen"
                                class="white--text"
                                @click="postHoliday"
                                >Confirmer ma demande</v-btn
                              >
                            </template>
                            <span>Cliquez-ici pour valider votre demande de congé</span>
                          </v-tooltip>
                        </v-card-actions> -->
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-dialog>
                        </template>

                        <template v-slot:no-data>
                          <v-alert color="error" text class="ma-2">
                            <div class="d-flex align-start">
                              <v-icon color="error"> mdi-alert-circle-outline </v-icon>

                              <div class="ms-3">
                                <p class="text-base font-weight-medium mb-1">
                                  Impossible d'accéder aux données. Merci de réessayer ultérieurement.
                                </p>
                              </div>
                            </div>
                          </v-alert>
                          <v-btn color="primary" class="mb-2" @click="fetchInsurersByProduct()"> Réessayer</v-btn>
                        </template>
                      </v-data-table>
                      <div class="text-center pt-2">
                        <v-pagination
                          v-model="page"
                          :length="pageCount"
                          :total-visible="5"
                          circle
                          navigation-color="EoleYellow"
                        ></v-pagination>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-else class="text-center justify-center">
                    <v-alert color="transparent">
                      <h2>Bonjour {{ collaborator.firstname }},</h2>

                      <v-img
                        class="mx-auto"
                        max-width="350"
                        :src="require('@/assets/images/pictogram/contact/getting_coffee.png')"
                      ></v-img>
                      Vous n'avez aucune demande de rappel. <br />
                      N'hésitez pas à aller vous chercher un café et à repasser dans quelques instants. 😉
                    </v-alert>
                  </v-row>
                </v-card-text>
              </v-tab-item>

              <v-tab-item :value="'tab-3'">
                <v-card-text class="no-padding">
                  <v-row v-if="contactTreated.length > 0">
                    <v-col cols="12">
                      <v-data-table
                        item-key="idContact"
                        class="elevation-0 no-padding"
                        :loading="loading"
                        loading-text="Chargement des produits en cours... Merci de patienter"
                        :page.sync="page"
                        :items-per-page="10"
                        :headers="headersContactSended"
                        :items="getContactsSendedByFilter()"
                        hide-default-footer
                        @page-count="pageCount = $event"
                        sort-by="calories"
                        :footer-props="{
                          'items-per-page-text': 'produits par page(s)',
                        }"
                      >
                        <template v-slot:top>
                          <v-row>
                            <v-col cols="12" md="4">
                              <v-text-field
                                prepend-icon="mdi-magnify"
                                v-model="searchContactSended"
                                label="Rechercher parmi vos demandes traitées"
                                class="mx-4"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item.agency="{ item }">
                          <v-chip small color="EoleYellow"> Agence de {{ item.agency }} </v-chip>
                        </template>
                        <template v-slot:item.status="{ item }">
                          <v-chip v-if="item.source === 'history'" color="EoleGreen" small outlined
                            >Demande traitée</v-chip
                          >
                          <v-chip v-else color="EoleError" small outlined>Demande non traitée</v-chip>
                        </template>
                        <template v-slot:item.receiver="{ item }">
                          {{ item.receiver.firstname }} {{ item.receiver.lastname }}
                        </template>
                        <template v-slot:item.firstname="{ item }"> {{ item.lastname }} {{ item.firstname }} </template>
                        <template v-slot:item.actions="{ item }">
                          <v-dialog max-width="1200">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" rounded icon fab>
                                <i class="fa-solid fa-eye"></i>
                              </v-btn>
                            </template>
                            <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                              <v-card-text style="background-color: #f1f4f9">
                                <v-row>
                                  <v-col cols="12" md="3" class="no-padding ml-n2">
                                    <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                                      <v-card-title>
                                        <v-img
                                          :src="require('@/assets/images/logos/anavel_logo.png')"
                                          max-height="150px"
                                          max-width="120px"
                                          alt="logo"
                                          contain
                                        ></v-img>
                                      </v-card-title>
                                      <v-card-title class="text-center justify-center muller-capitalized"
                                        >Mes Services Assurance</v-card-title
                                      >
                                      <v-card-subtitle class="text-center justify-center"
                                        >Un logiciel ANAVEL</v-card-subtitle
                                      >
                                      <v-card-text class="no-padding">
                                        <v-img
                                          :src="require('@/assets/images/logos/layer.png')"
                                          alt="logo"
                                          width="300px"
                                          class=""
                                        >
                                        </v-img>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                  <v-col cols="12" md="9" class="no-padding d-flex">
                                    <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                                      <v-card-title>
                                        <span class="text-h5">Votre demande portant le N°{{ item.idContact }}</span>
                                      </v-card-title>
                                      <v-card-text class="flex-grow-1">
                                        <v-row>
                                          <v-col class="text-left" cols="12" md="6"
                                            >Demande créée le {{ item.datetime }}
                                          </v-col>
                                          <v-col class="text-right" cols="12" md="6">
                                            <v-chip v-if="item.source === 'history'" color="EoleGreen" small
                                              >Demande traitée par {{ item.receiver.lastname }}
                                              {{ item.receiver.firstname }}</v-chip
                                            >
                                            <v-chip v-else color="EoleError" small>Demande non traitée</v-chip>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col class="text-right">
                                            <v-chip color="EoleYellow" small>Agence de {{ item.agency }}</v-chip>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col>
                                            <v-text-field
                                              dense
                                              id="identity"
                                              outlined
                                              label="Identité"
                                              append-icon="mdi-content-copy"
                                              readonly
                                              prepend-inner-icon="mdi-account"
                                              :value="item.lastname + ' ' + item.firstname"
                                              @click:append="copyClipboard('identity')"
                                            ></v-text-field>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col>
                                            <v-text-field
                                              dense
                                              id="email"
                                              outlined
                                              label="Email"
                                              append-icon="mdi-content-copy"
                                              readonly
                                              prepend-inner-icon="mdi-email"
                                              :value="item.email"
                                              @click:append="copyClipboard('email')"
                                            ></v-text-field>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col cols="12" md="7">
                                            <v-text-field
                                              dense
                                              id="phone"
                                              outlined
                                              label="Numéro de téléphone"
                                              append-icon="mdi-content-copy"
                                              readonly
                                              prepend-inner-icon="mdi-phone"
                                              :value="item.phoneNumber"
                                              @click:append="copyClipboard('phone')"
                                            ></v-text-field>
                                          </v-col>
                                          <v-col class="mt-2" cols="12" md="2">
                                            <v-btn
                                              class="white--text"
                                              :href="`tel:${item.phoneNumber}`"
                                              color="EoleBlue"
                                              >Appeler</v-btn
                                            >
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col>
                                            <v-text-field
                                              dense
                                              id="idGRC"
                                              outlined
                                              label="Identifiant GRC"
                                              append-icon="mdi-content-copy"
                                              readonly
                                              prepend-inner-icon="mdi-identifier"
                                              :value="item.idGRC"
                                              @click:append="copyClipboard('idGRC')"
                                            ></v-text-field>
                                          </v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col>
                                            <v-textarea
                                              dense
                                              id="reason"
                                              no-resize
                                              type="code"
                                              outlined
                                              label="Motif de l'appel"
                                              readonly
                                              :value="item.reason"
                                              rows="1"
                                              auto-grow
                                              append-icon="mdi-content-copy"
                                              @click:append="copyClipboard('reason')"
                                            ></v-textarea>
                                          </v-col>
                                        </v-row>
                                      </v-card-text>
                                      <v-spacer></v-spacer>
                                      <!-- <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-on="on"
                                v-bind="attrs"
                                :disabled="calculateDay - numDays < 0"
                                color="EoleGreen"
                                class="white--text"
                                @click="postHoliday"
                                >Confirmer ma demande</v-btn
                              >
                            </template>
                            <span>Cliquez-ici pour valider votre demande de congé</span>
                          </v-tooltip>
                        </v-card-actions> -->
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-dialog>
                        </template>

                        <template v-slot:no-data>
                          <v-alert color="error" text class="ma-2">
                            <div class="d-flex align-start">
                              <v-icon color="error"> mdi-alert-circle-outline </v-icon>

                              <div class="ms-3">
                                <p class="text-base font-weight-medium mb-1">
                                  Impossible d'accéder aux données. Merci de réessayer ultérieurement.
                                </p>
                              </div>
                            </div>
                          </v-alert>
                          <v-btn color="primary" class="mb-2" @click="fetchInsurersByProduct()"> Réessayer</v-btn>
                        </template>
                      </v-data-table>
                      <div class="text-center pt-2">
                        <v-pagination
                          v-model="page"
                          :length="pageCount"
                          :total-visible="5"
                          circle
                          navigation-color="EoleYellow"
                        ></v-pagination>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-else class="text-center justify-center">
                    <v-alert color="transparent">
                      <h2>Bonjour {{ collaborator.firstname }},</h2>

                      <v-img
                        class="mx-auto"
                        max-width="350"
                        :src="require('@/assets/images/pictogram/contact/getting_coffee.png')"
                      ></v-img>
                      Vous n'avez aucune demande de rappel. <br />
                      N'hésitez pas à aller vous chercher un café et à repasser dans quelques instants. 😉
                    </v-alert>
                  </v-row>
                </v-card-text>
              </v-tab-item>

              <v-tab-item :value="'tab-4'">
                <v-card-text class="text-left">
                  <v-row>
                    <v-col class="d-flex" cols="12" md="6" sm="12">
                      <v-card class="flex d-flex flex-column rounded-lg" outlined>
                        <v-row>
                          <v-col cols="12" md="2" class="text-left">
                            <v-card-title class="text-left">
                              <v-avatar color="EoleBlueLighten" class="mr-2">
                                <i
                                  class="fa-solid fa-chart-simple fa-1x EoleBlue--text"
                                  style="font-size: 1.5rem"
                                ></i> </v-avatar
                            ></v-card-title>
                          </v-col>
                          <v-col cols="12" md="10" class="text-left ml-n15">
                            <v-card-title class="text-left">Répartition par services des demandes</v-card-title>
                            <v-card-subtitle class="text-left">Pour l'année en cours</v-card-subtitle>
                          </v-col>
                        </v-row>

                        <v-row align="center">
                          <v-card-text class="text-center align-center">
                            <apexchart
                              :options="monthlyRequestsServicesChartOptions"
                              :series="monthlyRequestsServicesSeries"
                              type="bar"
                            ></apexchart>
                          </v-card-text>
                        </v-row>
                      </v-card>
                    </v-col>
                    <!-- <v-col class="d-flex" cols="12" md="6" sm="12">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-card class="flex d-flex flex-column rounded-lg" v-bind="attrs" v-on="on" outlined>
                            <v-row>
                              <v-col cols="12" md="2" class="text-left">
                                <v-card-title class="text-left">
                                  <v-avatar color="EoleBlueLighten" class="mr-2">
                                    <i
                                      class="fa-solid fa-chart-simple fa-1x EoleBlue--text"
                                      style="font-size: 1.5rem"
                                    ></i> </v-avatar
                                ></v-card-title>
                              </v-col>
                              <v-col cols="12" md="10" class="text-left ml-n15">
                                <v-card-title class="text-left"
                                  >Répartition total des demandes traitées pour l'agence</v-card-title
                                >
                                <v-card-subtitle class="text-left">Pour l'année en cours</v-card-subtitle>
                              </v-col>
                            </v-row>
                           
                            <v-row align="center">
                              <v-card-text class="text-center align-center">
                                <apexchart
                                  :options="monthlyRequestsChartOptions"
                                  :series="monthlyRequestsSeries"
                                  type="bar"
                                ></apexchart>
                              </v-card-text>
                            </v-row>
                          </v-card>
                        </template>
                        <span>Le nombre de réclamations de l'agence. </span>
                      </v-tooltip>
                    </v-col> -->
                    <v-col class="d-flex" cols="12" md="6" sm="12">
                      <v-card class="flex d-flex flex-column rounded-lg" outlined>
                        <v-row>
                          <v-col cols="12" md="2" class="text-left">
                            <v-card-title class="text-left">
                              <v-avatar color="EoleBlueLighten" class="mr-2">
                                <i
                                  class="fa-solid fa-chart-simple fa-1x EoleBlue--text"
                                  style="font-size: 1.5rem"
                                ></i> </v-avatar
                            ></v-card-title>
                          </v-col>
                          <v-col cols="12" md="10" class="text-left ml-n15">
                            <v-card-title class="text-left">Répartition sur mon service</v-card-title>
                            <v-card-subtitle class="text-left">Sur les demandes en cours</v-card-subtitle>
                          </v-col>
                        </v-row>
                        <!-- <v-card-subtitle
                              ><v-avatar color="EoleBlueLighten" class="mr-2">
                                <i class="fa-solid fa-chart-simple fa-1x EoleBlue--text" style="font-size: 1.5rem"></i>
                              </v-avatar>
                              Mes dernières demandes
                              </v-card-subtitle
                            >
                            <v-card-subtitle>
                              Sur les 7 derniers jours
                              </v-card-subtitle> -->

                        <v-row align="center">
                          <v-card-text class="text-center align-center">
                            <apexchart
                              type="pie"
                              :options="pieChartOptions"
                              :series="pieSeries"
                              height="250px"
                            ></apexchart>
                          </v-card-text>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex" cols="12" md="6" sm="6">
                      <v-card class="flex d-flex flex-column rounded-lg" outlined>
                        <v-row>
                          <v-col cols="12" md="2" class="text-left">
                            <v-card-title class="text-left">
                              <v-avatar color="EoleBlueLighten" class="mr-2">
                                <i
                                  class="fa-solid fa-chart-simple fa-1x EoleBlue--text"
                                  style="font-size: 1.5rem"
                                ></i> </v-avatar
                            ></v-card-title>
                          </v-col>
                          <v-col cols="12" md="10" class="text-left ml-n15">
                            <v-card-title class="text-left">Mes dernières demandes reçus</v-card-title>
                            <v-card-subtitle class="text-left">Sur les 7 derniers jours</v-card-subtitle>
                          </v-col>
                        </v-row>
                        <!-- <v-card-subtitle
                              ><v-avatar color="EoleBlueLighten" class="mr-2">
                                <i class="fa-solid fa-chart-simple fa-1x EoleBlue--text" style="font-size: 1.5rem"></i>
                              </v-avatar>
                              Mes dernières demandes
                              </v-card-subtitle
                            >
                            <v-card-subtitle>
                              Sur les 7 derniers jours
                              </v-card-subtitle> -->

                        <v-row align="center">
                          <v-card-text class="text-center align-center">
                            <apexchart type="bar" :options="chartOptions" :series="series"></apexchart>
                          </v-card-text>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col class="d-flex" cols="12" md="6" sm="6">
                      <v-card class="flex d-flex flex-column rounded-lg" outlined>
                        <v-row>
                          <v-col cols="12" md="2" class="text-left">
                            <v-card-title class="text-left">
                              <v-avatar color="EoleBlueLighten" class="mr-2">
                                <i
                                  class="fa-solid fa-chart-simple fa-1x EoleBlue--text"
                                  style="font-size: 1.5rem"
                                ></i> </v-avatar
                            ></v-card-title>
                          </v-col>
                          <v-col cols="12" md="10" class="text-left ml-n15">
                            <v-card-title class="text-left">Mes demandes reçus traitées</v-card-title>
                            <v-card-subtitle class="text-left">Sur les dernières années</v-card-subtitle>
                          </v-col>
                        </v-row>
                        <!-- <v-card-subtitle
                              ><v-avatar color="EoleBlueLighten" class="mr-2">
                                <i class="fa-solid fa-chart-simple fa-1x EoleBlue--text" style="font-size: 1.5rem"></i>
                              </v-avatar>
                              Mes dernières demandes
                              </v-card-subtitle
                            >
                            <v-card-subtitle>
                              Sur les 7 derniers jours
                              </v-card-subtitle> -->

                        <v-row align="center">
                          <v-card-text class="text-center align-center">
                            <apexchart :options="annualChartOptions" :series="annualSeries" type="bar"></apexchart>
                          </v-card-text>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="d-flex" cols="12" md="6" sm="6">
                      <v-card class="flex d-flex flex-column rounded-lg" outlined>
                        <v-row>
                          <v-col cols="12" md="2" class="text-left">
                            <v-card-title class="text-left">
                              <v-avatar color="EoleBlueLighten" class="mr-2">
                                <i
                                  class="fa-solid fa-share-from-square fa-1x EoleBlue--text"
                                  style="font-size: 1.5rem"
                                ></i> </v-avatar
                            ></v-card-title>
                          </v-col>
                          <v-col cols="12" md="10" class="text-left ml-n15">
                            <v-card-title class="text-left">Mes demandes envoyés</v-card-title>
                            <v-card-subtitle class="text-left">Sur les 7 derniers jours</v-card-subtitle>
                          </v-col>
                        </v-row>
                        <!-- <v-card-subtitle
                              ><v-avatar color="EoleBlueLighten" class="mr-2">
                                <i class="fa-solid fa-chart-simple fa-1x EoleBlue--text" style="font-size: 1.5rem"></i>
                              </v-avatar>
                              Mes dernières demandes
                              </v-card-subtitle
                            >
                            <v-card-subtitle>
                              Sur les 7 derniers jours
                              </v-card-subtitle> -->

                        <v-row align="center">
                          <v-card-text class="text-center align-center">
                            <apexchart type="bar" :options="sendChartOptions" :series="sendSeries"></apexchart>
                          </v-card-text>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col class="d-flex" cols="12" md="6" sm="6">
                      <v-card class="flex d-flex flex-column rounded-lg" outlined>
                        <v-row>
                          <v-col cols="12" md="2" class="text-left">
                            <v-card-title class="text-left">
                              <v-avatar color="EoleBlueLighten" class="mr-2">
                                <i
                                  class="fa-solid fa-share-from-square fa-1x EoleBlue--text"
                                  style="font-size: 1.5rem"
                                ></i> </v-avatar
                            ></v-card-title>
                          </v-col>
                          <v-col cols="12" md="10" class="text-left ml-n15">
                            <v-card-title class="text-left">Mes demandes envoyées</v-card-title>
                            <v-card-subtitle class="text-left">Sur les dernières années</v-card-subtitle>
                          </v-col>
                        </v-row>
                        <!-- <v-card-subtitle
                              ><v-avatar color="EoleBlueLighten" class="mr-2">
                                <i class="fa-solid fa-chart-simple fa-1x EoleBlue--text" style="font-size: 1.5rem"></i>
                              </v-avatar>
                              Mes dernières demandes
                              </v-card-subtitle
                            >
                            <v-card-subtitle>
                              Sur les 7 derniers jours
                              </v-card-subtitle> -->

                        <v-row align="center">
                          <v-card-text class="text-center align-center">
                            <apexchart
                              :options="annualSendChartOptions"
                              :series="annualSendSeries"
                              type="bar"
                            ></apexchart>
                          </v-card-text>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue"
import VueConfetti from "vue-confetti"
import config from "@/views/config/config.js"
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
init("user_gUunL4KW2ImPaGngWHYhP")
// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"
import moment from "moment"
moment.locale("fr")
import CustomeLoader from "@/layouts/components/LoaderCustom.vue"
import io from "socket.io-client"

const pause = ms => new Promise(resolve => setTimeout(resolve, ms))
Vue.use(VueConfetti)
// import Snowf from "vue-snowf"
import { sendNotification, socket } from "@/utils/socket.js"
import VueApexCharts from "vue-apexcharts"
import { getCookie } from "@/utils/cookies.js"
import { Vue2TinymceEditor } from "vue2-tinymce-editor"

export default {
  components: {
    Loading,
    CustomeLoader,
    apexchart: VueApexCharts,
    Vue2TinymceEditor,
  },
  data: () => ({
    exportStartDate: "",
    exportEndDate: "",
    filterCollaborator: "",
    searchContactSended: "",
    headersContactSended: [
      {
        text: "Agence",
        align: "start",
        value: "agency",
      },
      {
        text: "Date d'enregistrement",
        value: "datetime",
      },
      {
        text: "Identité client",
        value: "firstname",
      },
      {
        text: "Contact",
        value: "phoneNumber",
      },
      {
        text: "Envoyé à",
        value: "receiver",
      },
      {
        text: "État de la demande",
        value: "status",
      },
      {
        text: "Consulter la demande",
        value: "actions",
        align: "center",
      },
    ],
    contactSended: [],
    searchCollaborator: "",
    dataGraphLoaded: false,
    chartOptions: {
      chart: {
        type: "area",
        height: 100,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      xaxis: {
        categories: [],
        labels: {
          show: true,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: true,
        },
      },
      yaxis: {
        labels: {
          show: true,
        },
      },
      grid: {
        show: true,
      },
      tooltip: {
        enabled: true,
        fixed: {
          enabled: true,
          position: "topRight",
          offsetY: 0,
          offsetX: 100,
        },
        style: {
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
        },
        theme: "dark",
        x: {
          show: true,
        },
        y: {
          formatter: function (value) {
            return value
          },
        },
      },
      legend: {
        show: false,
      },
    },
    chartSeries: [],
    loaderContact: false,
    user: {},
    traceComment: "",
    dialogTrace: false,
    monthlyRequestsServicesChartOptions: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        categories: [], // Les noms des mois seront ajoutés ici
      },
      yaxis: {
        title: {
          text: "Nombre de demandes",
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " demandes"
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    },

    monthlyRequestsServicesSeries: [],
    monthlyRequestsSeries: [],
    monthlyRequestsChartOptions: {},
    pieChartOptions: {},
    pieSeries: [],
    sendSeries: [],
    sendChartOptions: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        // categories: [
        //   "01/01/2011 GMT",
        //   "01/02/2011 GMT",
        //   "01/03/2011 GMT",
        //   "01/04/2011 GMT",
        //   "01/05/2011 GMT",
        //   "01/06/2011 GMT",
        // ],
      },
      // legend: {
      //   position: "bottom",
      //   offsetY: 40,
      // },
      fill: {
        opacity: 1,
      },
    },
    annualSendSeries: [],
    annualSendChartOptions: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        // categories: [
        //   "01/01/2011 GMT",
        //   "01/02/2011 GMT",
        //   "01/03/2011 GMT",
        //   "01/04/2011 GMT",
        //   "01/05/2011 GMT",
        //   "01/06/2011 GMT",
        // ],
      },
      // legend: {
      //   position: "bottom",
      //   offsetY: 40,
      // },
      fill: {
        opacity: 1,
      },
    },
    annualSeries: [],
    annualChartOptions: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        // categories: [
        //   "01/01/2011 GMT",
        //   "01/02/2011 GMT",
        //   "01/03/2011 GMT",
        //   "01/04/2011 GMT",
        //   "01/05/2011 GMT",
        //   "01/06/2011 GMT",
        // ],
      },
      // legend: {
      //   position: "bottom",
      //   offsetY: 40,
      // },
      fill: {
        opacity: 1,
      },
    },
    statistics: [],
    series: [],
    // chartOptions: {
    //   chart: {
    //     type: "bar",
    //     height: 350,
    //     stacked: true,
    //     toolbar: {
    //       show: true,
    //     },
    //     zoom: {
    //       enabled: true,
    //     },
    //   },
    //   responsive: [
    //     {
    //       breakpoint: 480,
    //       options: {
    //         legend: {
    //           position: "bottom",
    //           offsetX: -10,
    //           offsetY: 0,
    //         },
    //       },
    //     },
    //   ],
    //   plotOptions: {
    //     bar: {
    //       horizontal: false,
    //       borderRadius: 10,
    //       dataLabels: {
    //         total: {
    //           enabled: true,
    //           style: {
    //             fontSize: "13px",
    //             fontWeight: 900,
    //           },
    //         },
    //       },
    //     },
    //   },
    //   xaxis: {
    //     // categories: [
    //     //   "01/01/2011 GMT",
    //     //   "01/02/2011 GMT",
    //     //   "01/03/2011 GMT",
    //     //   "01/04/2011 GMT",
    //     //   "01/05/2011 GMT",
    //     //   "01/06/2011 GMT",
    //     // ],
    //   },
    //   // legend: {
    //   //   position: "bottom",
    //   //   offsetY: 40,
    //   // },
    //   fill: {
    //     opacity: 1,
    //   },
    // },
    dialogTransferContact: false,
    avatarUrlCollaborator: null,

    avatarUrl: null,
    searchContactTreated: "",
    dialogArchiveContact: false,
    dialogContact: false,
    transferInProgress: false,
    clientTypologyProposed: [
      {
        name: "PROFESSIONNEL",
        value: 2,
        icon: "fa-building",
        e1: 2,
        hovered: false,
      },
      {
        name: "PARTICULIER",
        value: 1,
        icon: "fa-solid fa-people-arrows ",
        e1: 2,
        hovered: false,
      },
    ],
    e1: 1,
    collaboratorName: null,
    clientTypologie: null,
    singleExpand: true,
    expanded: [],
    loading: false,
    page: 1,
    pageCount: 0,
    headersContactTreated: [
      {
        text: "Agence",
        align: "start",
        value: "agency",
      },
      {
        text: "Date d'enregistrement",
        value: "datetime",
      },
      {
        text: "Identité client",
        value: "firstname",
      },
      {
        text: "Contact",
        value: "phoneNumber",
      },
      {
        text: "Reçu de",
        value: "attributor",
      },
      {
        text: "Consulter la demande",
        value: "actions",
        align: "center",
      },
    ],
    contactTotalCount: 0,
    tab: null,
    open: [],
    time: "",
    genderRules: [v => !!v || "Un genre est requis."],
    firstnameRules: [v => !!v || "Un prénom est requis."],
    lastnameRules: [v => !!v || "Un nom est requis."],
    agencyRules: [v => !!v || "Une agence est requise."],
    collaboratorRules: [v => !!v || "Un collaborateur à attribuer est requis."],
    reasonRules: [v => !!v || "Un motif est requis."],
    phoneRules: [
      v => !!v || "N° de téléphone requis",
      v => v.length === 10 || "Un numéro de téléphone Français est composé de 10 chiffres",
    ],
    validityFormContact: false,
    validityFormContactPro: false,
    waitTimeout: false,
    waitTimeoutContact: false,
    collaboratorsAvailable: [],
    grade: null,
    dialog: false,
    selection: [],
    idSelect: -1,
    nb: 0,
    active: [],
    // users
    emailSent: {},
    statistics: [],
    contact: [],

    collaborators: [],
    collaboratorsTreated: [],
    contactTreated: [],

    collaborator: [],

    idLast: -1,
    editedIndex: -1,
    editedItem: {
      id: null,
      name: "",
      gender: "",
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      idGRC: "",
      agence: "",
      reason: "",
    },
    //Pour mettre vide faire avec editedDefault
    pageContact: 1,
  }),
  mounted() {
    socket.on("notification", data => {
      if (this.$store.state.user.grade === "collaborateur" || this.$store.state.user.grade === "manager") {
        this.fetchAccountCounter()
        this.fetchUsers()
        this.fetchUserContact(this.user.id)
        this.fetchUserContactTreated(this.user.id)
        this.getImgCollaborator(this.user.lastname, this.user.firstname)
      } else {
        this.fetchUsers()
        this.fetchUserContactTreated(this.$store.state.user.id)
      }
    })
  },
  computed: {
    paginatedContact() {
      const start = (this.pageContact - 1) * 3
      const end = start + 3
      return this.contact.slice(start, end)
    },
    pageContactCount() {
      return Math.ceil(this.contact.length / 3)
    },
    items() {
      return [
        {
          id: 1,
          name: `Collaborateurs en activité (${this.collaborators.length})`,
          children: this.collaborators,
        },
      ]
    },
    selected() {
      if (!this.active.length) return undefined

      const id = this.active[0]

      this.getImg(
        this.collaborators.find(user => user.id === id).lastname,
        this.collaborators.find(user => user.id === id).firstname,
      )

      this.contact = []
      this.fetchUserContact(id)

      this.fetchUserContactTreated(id)

      this.fetchUserContactSended(id)

      this.fetchAccountCounter(id)

      return this.collaborators.find(collaborators => collaborators.id === id)
    },
  },
  async created() {
    this.user = this.$store.state.user

    if (this.user.grade === "collaborateur" || this.user.grade === "manager") {
      this.fetchAccountCounter(this.user.id)
      this.fetchUsers()
      this.fetchUserContact(this.user.id)
      this.fetchUserContactTreated(this.user.id)
      this.fetchUserContactSended(this.user.id)
      this.getImgCollaborator(this.user.lastname, this.user.firstname)
    }
  },
  // watch: {
  //   collaboratorsAvailable: {
  //     handler() {
  //       this.updateChart()
  //     },
  //     deep: true,
  //   },
  methods: {
    async exportContacts() {
      const idAgency = this.$store.state.user.agency_id
      const response = await fetch(`${config.apiUri}/agencies/${idAgency}/contacts/export`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("jwt")}`,
        },
        body: JSON.stringify({
          dateStart: this.exportStartDate,
          dateEnd: this.exportEndDate,
        }),
      })

      if (response.ok) {
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = url
        a.download = "contacts.xlsx"
        a.click()
        window.URL.revokeObjectURL(url)
      } else {
        console.error("Failed to export contacts")
      }
    },
    filterItems(item) {
      let search = this.filterCollaborator.toLowerCase()
      return [
        {
          id: 1,
          name: `Mon équipe en activité (${this.users.length})`,
          children: item.filter(user => user.completeName.toLowerCase().includes(search)),
          open: false,
        },
      ]
    },
    getContactsByFilter() {
      return this.contactTreated.filter(contact => {
        let complete_name = `${contact.firstname} ${contact.lastname}`
        const searchQuery = this.searchContactTreated.toLowerCase()

        const isNameValid = complete_name.toLowerCase().includes(searchQuery)
        const isPhoneNumberValid = contact.phoneNumber.includes(searchQuery)

        return isNameValid || isPhoneNumberValid
      })
    },
    getContactsSendedByFilter() {
      return this.contactSended.filter(contact => {
        let complete_name = `${contact.firstname} ${contact.lastname}`
        const searchQuery = this.searchContactSended.toLowerCase()

        const isNameValid = complete_name.toLowerCase().includes(searchQuery)
        const isPhoneNumberValid = contact.phoneNumber.includes(searchQuery)

        return isNameValid || isPhoneNumberValid
      })
    },

    async fetchUserContactSended(id) {
      this.loaderContact = true
      try {
        const response = await fetch(`${config.apiUri}/accounts/${id}/contacts/sended`, {
          headers: {
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })
        const data = await response.json()

        if (response.ok) {
          this.contactSended = data
          this.loaderContact = false
        } else {
          throw new Error("Failed to fetch user contacts")
        }

        this.contactSended.forEach(contact => {
          contact.datetime = moment(contact.datetime).format("dddd Do MMM à HH:mm")
        })
      } catch (error) {
        console.error(error)
      }
    },

    collaboratorFiltering(collaborators) {
      return collaborators.filter(collaborator => {
        const isUserValid = collaborator.completName.toLowerCase().includes(this.searchCollaborator.toLowerCase())

        return isUserValid
      })
    },
    getChartData() {
      const allDates = this.collaboratorsAvailable.flatMap(collaborator =>
        collaborator.last_5_days_contact.map(contact => contact.date),
      )


      const uniqueDates = [...new Set(allDates)].sort((a, b) => moment(a).diff(moment(b)))


      
      const seriesData = this.collaboratorsAvailable.map(collaborator => {
        return {
          name: `${collaborator.firstname} ${collaborator.lastname}`,
          data: uniqueDates.map(date => {
            const contact = collaborator.last_5_days_contact.find(c => c.date === date)
            return contact ? contact.count : 0
          }),
        }
      })

      this.chartOptions.xaxis.categories = uniqueDates

      this.chartSeries = seriesData

      
      this.dataGraphLoaded = true
    },
    prepareOutlookMail(call) {
      const subject = `Demande de rappel - ${call.firstname} ${call.lastname}`
      const body = `Bonjour,%0D%0A%0D%0A tu trouveras ci-joint les informations concernant la demande de rappel de ${
        call.firstname
      } ${call.lastname} : %0D%0A%0D%0A - Agence : ${call.agency} %0D%0A%0D%0A - Date de la demande : ${
        call.datetime
      } %0D%0A%0D%0A - Contact : ${call.phoneNumber} %0D%0A%0D%0A - Email : ${
        call.email
      } %0D%0A%0D%0A - Motif : ${this.decodeHTMLEntities(
        this.sanitizeReasonAndRemoveHTMLTags(call.reason),
      )} %0D%0A%0D%0A
        Cordialement, %0D%0A%0D%0A ${this.user.firstname} ${this.user.lastname}`
      const mailto = `mailto:?subject=${subject}&body=${body}`
      window.open(mailto)
    },

    sanitizeReasonAndRemoveHTMLTags(reason) {
      return reason.replace(/<[^>]*>?/gm, "")
    },
    decodeHTMLEntities(text) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(text, "text/html")
      return doc.documentElement.textContent
    },
    reverseTrace(array) {
      return [...array].reverse()
    },
    async postComment(call, comment) {
      // Ajout du commentaire à l'interface utilisateur avant la confirmation de l'API
      call.trace.push({
        date: new Date().toISOString(),
        datetime: moment().format("dddd Do MMM à HH:mm"),
        comments: comment,
        action: "Commentaire",
        id_action: 2,
      })

      const idAccount = this.$store.state.user.id

      // Préparation des données à envoyer en JSON
      const postData = {
        idContact: call.idContact,
        comments: comment,
        idAccount: idAccount,
        idAction: 2, // Assurez-vous que cet id d'action correspond à "Commentaire" dans votre base de données
      }

      try {
        const response = await fetch(`${config.apiUri}/contact-traces`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Assurez-vous d'inclure le token JWT si nécessaire
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify(postData),
        })

        if (response.ok) {
          this.$toast.success("Commentaire ajouté avec succès", {
            position: "bottom-right",
            duration: 3000,
          })
        } else {
          throw new Error("Failed to post comment") // Lever une exception en cas d'échec de la requête
        }
      } catch (error) {
        console.error(error) // Consoler l'erreur pour le débogage
        this.$toast.error("Erreur lors de l'ajout du commentaire", {
          position: "bottom-right",
          duration: 3000,
        })
      }
    },

    async updateStackedBarChartData(monthlyData) {
      // Définir les catégories de l'axe X avec les noms des mois
      this.monthlyRequestsServicesChartOptions.xaxis.categories = monthlyData.map(data => data.month_name)

      // Initialiser un objet pour collecter les séries par service
      let seriesMap = {}

      // Préparer un tableau de données initial contenant 0 pour chaque mois
      const initialData = new Array(monthlyData.length).fill(0)

      monthlyData.forEach((month, index) => {
        month.services.forEach(service => {
          if (!seriesMap[service.service_name]) {
            // Utiliser une copie du tableau initialData pour chaque nouveau service
            seriesMap[service.service_name] = { name: service.service_name, data: [...initialData] }
          }
          // Remplacer 0 par le total_requests pour le mois concerné
          seriesMap[service.service_name].data[index] = service.total_requests
        })
      })

      // Convertir l'objet des séries en tableau
      this.monthlyRequestsServicesSeries = Object.values(seriesMap)
    },
    async updateMonthlyCallbackRequestsChart(monthlyCallbackRequests) {
      // Extraction des labels (noms des mois) et des valeurs (total des demandes)
      const labels = monthlyCallbackRequests.map(item => item.month_name)
      const seriesData = monthlyCallbackRequests.map(item => item.total_requests)

      // Mise à jour des options du graphique et des séries de données
      this.monthlyRequestsChartOptions = {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis: {
          categories: labels,
        },
        yaxis: {
          title: {
            name: "Demandes de rappel mensuelles",
          },
        },
        // title: {
        //   text: "Monthly Callback Requests",
        //   align: "center",
        // },
        colors: ["#00E396"], // Couleur des barres
      }

      this.monthlyRequestsSeries = [
        {
          name: "Demandes de rappel mensuelles",
          data: seriesData,
        },
      ]
    },
    async updatePieChartData(requestsDistribution) {
      // Filtrer pour exclure l'élément total_requests
      const filteredData = requestsDistribution.filter(item => item.id_account)

      // Préparer les labels et les valeurs pour le graphique en secteurs
      const labels = filteredData.map(item => `${item.firstname} ${item.lastname}`)
      const series = filteredData.map(item => parseInt(item.request_count, 10))

      // Mettre à jour les données du graphique pour ApexCharts
      this.pieChartOptions = {
        chart: {
          type: "pie",
          height: "100px",
        },
        labels: labels,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
                height: 200,
              },
              legend: {
                position: "left",
              },
            },
          },
        ],
      }

      this.pieSeries = series

      // Assurez-vous d'avoir `pieChartOptions` et `pieSeries` définis dans la section `data` de votre composant Vue
    },
    async updateChartData(weeklyRequests) {
      // Préparer les données pour les séries du graphique
      const dataDemandesEnCours = weeklyRequests.map(item => ({
        x: moment(item.request_date).format("Do MMM"), // La date de la demande
        y: item.request_count_contact, // Le nombre de demandes en cours pour cette date
      }))

      const dataDemandesTraitees = weeklyRequests.map(item => ({
        x: moment(item.request_date).format("Do MMM"), // La date de la demande
        y: item.request_count_contact_history, // Le nombre de demandes traitées pour cette date
      }))

      // Mettre à jour les séries de données pour le graphique
      this.series = [
        {
          name: "Demandes en cours",
          data: dataDemandesEnCours,
          color: "#FCC03C", // Rouge, par exemple
          foreColor: "#FF4C51",
        },
        {
          name: "Demandes traitées",
          data: dataDemandesTraitees,
          color: "#001F47", // Vert, par exemple
          foreColor: "#FF4C51",
        },
      ]
    },
    async updateAnnualChartData(annualRequests) {
      // Préparer les données pour les séries du graphique
      const dataDemandesTraiteesAnnuelles = annualRequests.map(item => ({
        x: item.request_year.toString(), // Convertir l'année en chaîne pour l'affichage
        y: item.request_count_treated, // Le nombre de demandes traitées pour cette année
      }))

      // Mettre à jour les séries de données pour le graphique
      this.annualSeries = [
        {
          name: "Demandes traitées annuellement",
          data: dataDemandesTraiteesAnnuelles,
          color: "#001F47", // Vert, par exemple
        },
      ]
    },
    async updateSendChartData(weeklySendRequests) {
      // Préparer les données pour les séries du graphique
      const dataDemandesEnvoyees = weeklySendRequests.map(item => ({
        x: moment(item.request_date).format("Do MMM"), // La date de la demande
        y: item.request_count_sent, // Le nombre de demandes envoyées pour cette date
      }))

      // Mettre à jour les séries de données pour le graphique
      this.sendSeries = [
        {
          name: "Demandes envoyées",
          data: dataDemandesEnvoyees,
          color: "#9D9D9C", // Rouge, par exemple
        },
      ]
    },
    async updateAnnualSendChartData(annualSendRequests) {
      // Préparer les données pour les séries du graphique
      const dataDemandesEnvoyeesAnnuelles = annualSendRequests.map(item => ({
        x: item.request_year.toString(), // Convertir l'année en chaîne pour l'affichage
        y: item.request_count_sent, // Le nombre de demandes envoyées pour cette année
      }))

      // Mettre à jour les séries de données pour le graphique
      this.annualSendSeries = [
        {
          name: "Demandes envoyées annuellement",
          data: dataDemandesEnvoyeesAnnuelles,
          color: "#9D9D9C", // Rouge, par exemple
        },
      ]
    },
    async fetchAccountCounter(idAccount) {
      this.account = []

      let headers = new Headers()

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")
      headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

      try {
        let response = await fetch(`${config.apiUri}/accounts/${idAccount}/counter`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()
        if (response.ok) {
          this.updateChartData(data.weekly_requests)
          this.updateAnnualChartData(data.annual_treated_requests)
          this.updateSendChartData(data.weekly_send_requests)
          this.updateAnnualSendChartData(data.annual_send_requests)
          this.updatePieChartData(data.requests_distribution)
          this.updateMonthlyCallbackRequestsChart(data.monthly_callback_requests)

          this.updateStackedBarChartData(data.monthly_callback_requests_services)
        } else {
          throw new Error("Failed to fetch account counter")
        }
      } catch (e) {
        console.log(e)
      }
    },
    onPaste() {
      //verify phoneRules

      if (this.editedItem.phoneNumber.length === 10) {
        this.editedItem.phoneNumber = this.editedItem.phoneNumber.replace(/ /g, "")
      } else {
        this.editedItem.phoneNumber = this.editedItem.phoneNumber.replace(/ /g, "")
        this.editedItem.phoneNumber = this.editedItem.phoneNumber.replace(
          /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
          "$1$2$3$4$5",
        )
      }
    },
    test() {
      alert("GED POUR :")
    },

    func() {
      this.collaborators.forEach(el => {
        if (el.id === this.editedItem.id) {
          this.emailSent = {
            firstname: el.firstname,
            lastname: el.lastname,
            mail: el.email,
          }
        }
      })
    },

    async transferContact(idContact, newIdAccount) {
      this.transferInProgress = true
      const idAccount = this.$store.state.user.id

      if (
        newIdAccount !== idAccount ||
        this.$store.state.user.grade === "manager" ||
        this.$store.state.user.grade === "administrateur" ||
        this.$store.state.user.grade === "superadministrateur"
      ) {
        try {
          let headers = new Headers()
          headers.append("Content-Type", "application/json")
          headers.append("Accept", "application/json")
          headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

          let response = await fetch(`${config.apiUri}/contacts/transfer`, {
            mode: "cors",
            method: "POST",
            headers: headers,
            body: JSON.stringify({
              idContact: idContact,
              newIdAccount: newIdAccount,
            }),
          })

          if (response.ok) {
            sendNotification(
              newIdAccount.toString(),
              1,
              "Nouvelle demande de rappel",
              "Vous venez de recevoir un transfert de demande de rappel.",
            )

            // socket.emit("notification", {
            //   roomName: newIdAccount,
            //   type: 1,
            //   info: {
            //     title: "Nouvelle demande de rappel",
            //     action: "Vous venez de recevoir un transfert de demande de rappel.",
            //     datetime: new Date(),
            //   } 
            // })

            this.dialogContact = false
            this.transferInProgress = false
            let index = this.contact.map(ob => ob.idContact).indexOf(idContact)
            this.contact.splice(index, 1)
            this.dialogTransferContact = false

            this.$toast.success(
              `Vous avez transféré l'appel à ${
                this.collaborators.find(collaborator => collaborator.id === newIdAccount).firstname
              } ${this.collaborators.find(collaborator => collaborator.id === newIdAccount).lastname}`,
              {
                position: "bottom-right",
                timeout: 5000,
              },
            )
          } else {
            const error = await response.json()
            this.$toast.error(`Une erreur est survenue.\nImpossible de transfèrer l'appel: ${error.message}`, {
              position: "bottom-right",
              timeout: 5000,
            })
            this.transferInProgress = false
            this.dialogTransferContact = false
          }
        } catch (e) {
          console.error(e)
          this.$toast.error(`Une erreur est survenue.\nImpossible de transfèrer l'appel`, {
            position: "bottom-right",
            timeout: 5000,
          })

          this.transferInProgress = false
          this.dialogTransferContact = false
        }
      } else {
        this.transferInProgress = false
        this.dialogTransferContact = false

        this.$toast.error(`Vous ne pouvez pas vous transférez l'appel à vous même`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }
    },

    async archiveContact(idContact) {
      this.$confetti.start({
        // Configuration des particules pour le confetti ici
      })

      setTimeout(() => {
        this.$confetti.stop()
      }, 4000)

      try {
        let headers = new Headers()
        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        // Supposons que votre API attend des données supplémentaires pour l'archivage, vous pouvez les inclure dans le corps de la requête.
        let response = await fetch(`${config.apiUri}/contacts/archive/${idContact}`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify({ idContact: idContact }), // Envoyer l'ID du contact dans le corps de la requête
        })

        if (response.ok) {
          this.$toast.success(`Vous avez archivé cet appel.`, {
            position: "bottom-right",
            timeout: 5000,
          })

          this.fetchUserContact(this.$store.state.user.id)
          this.fetchUserContactTreated(this.$store.state.user.id)
        } else {
          throw new Error("Failed to archive contact")
        }
      } catch (e) {
        console.error(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible d'archiver le contact`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }
    },
    validateFormContact(itemId) {
      if (this.clientTypologie === 1) {
        this.validityFormContact = true
        if (this.$refs.formContact.validate()) {
          this.save(itemId)
        }
      } else {
        this.validityFormContactPro = true
        if (this.$refs.formContactPro.validate()) {
          this.save(itemId)
        }
      }
    },

    copyClipboard(idElement) {
      let copyText = document.getElementById(idElement)

      try {
        copyText.select()
        navigator.clipboard.writeText(copyText.value)

        this.$toast.success(`Le texte a été copié dans votre presse-papier.`, {
          position: "bottom-right",
          timeout: 2000,
        })
      } catch (e) {
        this.$toast.error(`Impossible de copier le texte dans votre presse-papier.`, {
          position: "bottom-right",
          timeout: 2000,
        })
      }
    },

    async fetchAttributorById(index, idAttributor) {
      let headers = new Headers()
      headers.append("Content-Type", "application/json charset=UTF-8")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      await pause(1500)

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")

        const response = await fetch(`${config.apiUri}/accounts/${idAttributor}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        data.forEach(element => {
          this.contact[index].attributorName = element.lastname + " " + element.firstname
        })
      } catch (e) {
        console.log(e)
      }
    },
    async fetchUserContact(idCollaborator) {
      this.waitTimeoutContact = true
      this.contact = []

      let headers = new Headers()
      headers.append("Content-Type", "application/json charset=UTF-8")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")
      headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

      await pause(1500)

      try {
        const response = await fetch(`${config.apiUri}/accounts/${idCollaborator}/contacts`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        const contacts = data

        if (response.ok) {
          contacts.forEach(element => {
            element.trace.forEach(trace => {
              trace.datetime = moment(trace.date).format("dddd Do MMM à HH:mm")
            })
            this.contact.push({
              id: element.id_account,
              idContact: element.id,
              lastname: element.lastname,
              firstname: element.firstname,
              phoneNumber: element.phone_number,
              idGRC: element.idGRC,
              agency: element.agency,
              reason: element.reason,
              attributor: element.attributor,
              datetime: moment(element.date_time).format("dddd Do MMM à HH:mm"),
              email: element.email,
              trace: element.trace,
            })

            // this.fetchAttributorById(this.contact.map(ob => ob.idContact).indexOf(element.id), element.id_attributor)
          })

          this.collaborators.forEach(collaborator => {
            if (collaborator.id === idCollaborator) {
              collaborator.contactNumber = contacts.length
              this.contactTotalCount += contacts.length
            }
          })

          this.waitTimeoutContact = false
        }
      } catch (e) {
        console.log(e)
      }
    },

    async fetchUserContactTreated(idCollaborator) {
      this.contactTreated = []
      this.loading = true

      let headers = new Headers()
      headers.append("Content-Type", "application/json charset=UTF-8")
      headers.append("Accept", "application/json")
      headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

      await pause(1500)

      try {
        const responseContact = await fetch(`${config.apiUri}/accounts/${idCollaborator}/contacts/history`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const dataContact = await responseContact.json()

        dataContact.forEach(element => {
          this.contactTreated.push({
            id: element.id_account,
            idContact: element.id,
            lastname: element.lastname,
            firstname: element.firstname,
            phoneNumber: element.phone_number,
            idGRC: element.idGRC,
            agency: element.agency,
            reason: element.reason,
            attributorName: null,
            datetime: moment(element.date_time).format("dddd Do MMM à HH:mm"),
            email: element.email,
            attributor: element.attributor,
          })

          // this.fetchAttributorById(
          //   this.contactTreated.map(ob => ob.idContact).indexOf(element.id),
          //   element.id_attributor,
          // )
        })

        this.collaboratorsTreated.forEach(collaborator => {
          if (collaborator.id === idCollaborator) {
            collaborator.contactNumber = dataContact.length
          }
        })
        this.loading = false
      } catch (e) {
        console.log(e)
      }
    }, // get image in folder asset for avatar and display error if not found
    getImgCollaborator(lastname, firstname) {
      this.imgError = false

      try {
        let name = `${lastname.toLowerCase()}-${firstname.toLowerCase()}`
        let agency = localStorage.getItem("agency").toLowerCase().replace(/ /g, "_")
        let pictureProfilPng = `${config.ged}/${agency}/users/${name}/profil.png`

        let pictureProfilJpg = `${config.ged}/${agency}/users/${name}/profil.jpg`

        let img = new Image()
        img.onload = () => {
          this.avatarUrlCollaborator = img.src
        }
        img.onerror = () => {
          this.imgError = true
          this.avatarUrlCollaborator = null
        }

        // Test pour l'image .png
        img.src = pictureProfilPng

        // Si l'image .png n'est pas disponible, tester pour l'image .jpg
        setTimeout(() => {
          if (this.avatarUrlCollaborator == null) {
            img.src = pictureProfilJpg
          }
        }, 500)
      } catch (error) {
        this.imgError = true
        this.avatarUrlCollaborator == null
      }
    },
    getImg(lastname, firstname) {
      this.imgError = false

      try {
        let name = `${lastname.toLowerCase()}-${firstname.toLowerCase()}`
        let agency = localStorage.getItem("agency").toLowerCase().replace(/ /g, "_")
        let pictureProfilPng = `${config.ged}/${agency}/users/${name}/profil.png`

        let pictureProfilJpg = `${config.ged}/${agency}/users/${name}/profil.jpg`

        let img = new Image()
        img.onload = () => {
          this.avatarUrl = img.src
        }
        img.onerror = () => {
          this.imgError = true
          this.avatarUrl = null
        }

        // Test pour l'image .png
        img.src = pictureProfilPng

        // Si l'image .png n'est pas disponible, tester pour l'image .jpg
        setTimeout(() => {
          if (this.avatarUrl == null) {
            img.src = pictureProfilJpg
          }
        }, 500)
      } catch (error) {
        this.imgError = true
        this.avatarUrl == null
      }
    },
    async getImg2(lastname, firstname) {
      try {
        const name = `${lastname.toLowerCase().replace(" ", "")}-${firstname.toLowerCase()}`
        const agency = this.$store.state.user.agency_name.toLowerCase().replace(/ /g, "_")
        const pictureProfilPng = `${config.ged}/${agency}/users/${name}/profil.png`
        const pictureProfilJpg = `${config.ged}/${agency}/users/${name}/profil.jpg`

        // Create a Promise to handle image loading
        const loadImage = src => {
          return new Promise((resolve, reject) => {
            const img = new Image()
            img.onload = () => resolve(img.src)
            img.onerror = () => reject(null) // Failed to load image
            img.src = src
          })
        }

        // Attempt to load the PNG image
        let result = await loadImage(pictureProfilPng)

        // If the PNG image fails to load, try the JPG image
        if (!result) {
          result = await loadImage(pictureProfilJpg)
        }

        return result // Return the URL or null if no image is found
      } catch (error) {
        return null // Return null in case of any errors
      }
    },
    async fetchUsers() {
      this.collaborators = []
      this.collaborator = []
      this.collaboratorsAvailable = []
      this.waitTimeout = true

      await pause(1500)

      try {
        let headers = new Headers()
        let userId = localStorage.getItem("user_id")
        let idAgency = this.$store.state.user.agency_id

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/collaborators`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        await Promise.all(
          data.map(async (element, index) => {
            const avatarUrl = await this.getImg2(element.lastname, element.firstname)

            if (element.available == 1 && element.disabled == 0) {
              if (element.id != userId) {
                this.collaboratorsAvailable.push({
                  id: element.id,
                  lastname: element.lastname,
                  completName: element.lastname + " " + element.firstname,
                  firstname: element.firstname,
                  email: element.email,
                  contactNumber: 0,
                  avatarUrl: avatarUrl,
                  contact_number: parseInt(element.number_contact),
                  last_5_days_contact: element.last_5_days_contact,
                  available: element.available,
                })
              }
            }

            if (element.disabled == 0) {
              this.collaborators.push({
                id: element.id,
                lastname: element.lastname,
                completName: element.lastname + " " + element.firstname,
                firstname: element.firstname,
                email: element.email,
                contactNumber: 0,
                avatarUrl: avatarUrl,
                available: element.available,
              })
              this.collaboratorsTreated.push({
                id: element.id,
                lastname: element.lastname,
                completName: element.lastname + " " + element.firstname,
                email: element.email,
                contactNumber: 0,
                avatarUrl: avatarUrl,
                available: element.available,
              })
            }
          }),
        )

        this.collaborators.forEach((collaborator, index) => {
          if (collaborator.id == userId && this.grade === "collaborateur") {
            this.collaborators.splice(index, 1)
          }
        })

        // if (this.grade === "administrateur" || this.grade === "superadministrateur") {
        //   this.collaborators.forEach(c => {
        //     this.fetchUserContact(c.id)
        //   })
        // } else {
        //   this.collaborator = {
        //     firstname: localStorage.getItem("firstname"),
        //     lastname: localStorage.getItem("lastname"),
        //     completName: localStorage.getItem("lastname") + " " + localStorage.getItem("firstname"),
        //     contactNumber: 0,
        //   }

        //   this.fetchUserContact(userId)
        // }

        this.waitTimeout = false

        this.setSolicitedStatus()

        this.getChartData()
      } catch (e) {
        console.log(e)
        this.loading = false
        this.fetch = false
        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    calculateTotalContacts(collaborator) {
      if (collaborator.last_5_days_contact) {
        return collaborator.last_5_days_contact.reduce((sum, day) => sum + day.count, 0)
      }
      return 0
    },
    setSolicitedStatus() {
      // Calculez le nombre total de contacts des 5 derniers jours pour chaque collaborateur
      this.collaboratorsAvailable.forEach(collaborator => {
        collaborator.contact_number = this.calculateTotalContacts(collaborator)
      })

      // Filtrez les collaborateurs avec un contact_number supérieur à 0
      const collaboratorsWithContacts = this.collaboratorsAvailable.filter(c => c.contact_number > 0)

      // Triez le tableau par contact_number décroissant pour ceux avec des contacts
      collaboratorsWithContacts.sort((a, b) => b.contact_number - a.contact_number)

      // Calculez la médiane des contact_number pour les collaborateurs filtrés
      const median = this.calculateMedian(collaboratorsWithContacts.map(c => c.contact_number))

      // Attribuez le statut de sollicitation en fonction de la médiane
      this.collaboratorsAvailable.forEach(collaborator => {
        if (collaborator.contact_number === 0) {
          collaborator.solicitationStatus = 0 // Pas de sollicitation
        } else if (collaborator.contact_number > median) {
          collaborator.solicitationStatus = 1 // Plus sollicité que la médiane
        } else if (collaborator.contact_number === median) {
          collaborator.solicitationStatus = 2 // Égal à la médiane
        } else {
          collaborator.solicitationStatus = 3 // Moins sollicité que la médiane
        }
      })
    },
    calculateMedian(numbers) {
      const sorted = numbers.slice().sort((a, b) => a - b)
      const middle = Math.floor(sorted.length / 2)

      if (sorted.length % 2 === 0) {
        return (sorted[middle - 1] + sorted[middle]) / 2
      } else {
        return sorted[middle]
      }
    },
    async post(data) {
      this.loaderContact = true
      try {
        // Préparation de l'objet de données à envoyer
        const postData = {
          idAccount: data.id_account,
          lastname: data.lastname,
          firstname: data.firstname,
          phoneNumber: data.phone_number,
          idGRC: data.idGRC,
          agency: data.agency,
          reason: data.reason,
          idAttributor: data.id_attributor,
          email: data.email,
          gender: data.gender,
        }

        // Préparation des en-têtes de la requête, y compris l'en-tête d'autorisation
        const headers = new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getCookie("jwt")}`, // Assurez-vous de récupérer le token d'authentification correctement
        })

        // Effectuer la requête POST
        const response = await fetch(`${config.apiUri}/contacts`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(postData),
        })

        if (response.ok) {
          sendNotification(
            data.id_account.toString(),
            1,
            "Nouvelle demande de rappel",
            "Vous avez une nouvelle demande de rappel à traiter.",
          )

          this.$toast.success("Demande de rappel envoyée avec succès", {
            position: "bottom-right",
            timeout: 3000,
          })

          this.close()
          this.loaderContact = false

          this.editedItem = {
            name: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            idGRC: "",
            agence: "",
            reason: "",
            email: "",
            gender: "",
          }

          this.clientTypologie === 1 ? this.$refs.formContact.reset() : this.$refs.formContactPro.reset()
        } else {
          const error = await response.json()
          console.log(error)

          this.$toast.error(`Impossible de transmettre la demande de rappel : ${error.message}`, {
            position: "bottom-right",
            timeout: 3000,
          })
        }

        this.nb = null
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\n${e}`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }

      this.loaderContact = false
    },
    save(idUser) {
      this.idLast = idUser
      this.post({
        id_account: this.idLast,
        firstname: this.editedItem.firstName,
        lastname: this.editedItem.lastName,
        phone_number: this.editedItem.phoneNumber,
        idGRC: this.editedItem.idGRC,
        agency: this.editedItem.agence,
        reason: this.editedItem.reason,
        id_attributor: this.$store.state.user.id,
        email: this.editedItem.email,
        gender: this.editedItem.gender,
      })
    },

    close() {
      this.dialog = false
      this.e1 = 1
    },
  },
}
</script>
<style lang="scss" scoped>
.timeline-container {
  max-height: 585px;
  overflow-y: auto;
}

.timeline-container::-webkit-scrollbar {
  width: 10px;
  height: 10px !important;
  background-color: #f5f5f5; /* Couleur de fond de la barre de défilement */
}
.timeline-container::-webkit-scrollbar-track {
  background-color: #fcc03c; /* Couleur de fond de la barre de défilement */
}

.timeline-container::-webkit-scrollbar-thumb {
  background-color: #001f47; /* Couleur de la barre de défilement */
  border-radius: 20px;
}

.profilTabs {
  position: absolute;
  top: 32px;
  left: 115px;
}

// if media screen is more than 1250px width then apply the following styles
@media screen and (min-width: 2000px) {
  .profilBadge {
    position: absolute;
    top: 150px;
    z-index: 0;
    width: 0%;
  }
}

@media screen and (min-width: 1500px) {
  .profilBadge {
    position: absolute;
    top: 45px;
    z-index: 0;
    width: 0%;
  }
}

.profilBannerAccount {
  // background-image: url("../../../../src/assets/images/profils/agence.png");
  height: 100%;
  width: 100%;
}

.no-padding {
  padding: 0 !important;
}

.contact-study {
  max-width: 450px;
}

.no-padding {
  padding: 0 !important;
}

.v-treeview:hover {
  cursor: pointer;
}

@media (max-width: 600px) {
  .services {
    padding: 1.5rem !important;
  }

  .favorite-card {
    width: 100% !important;
  }
}

.greeting-card {
  position: relative;

  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}

// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }

    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}

.call-card {
  // background-color: #f5f5f5;
  // padding: 10px;
  // border-radius: 10px;
  // border: 1px solid #e0e0e0;
}
</style>
